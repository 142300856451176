const init = {
    status:false,
    message:'',
    data:{}
}

export const getCardsReducer = (state = init, action) => {
    switch (action.type){
        case 'GET_CARD_START':
            return state;
        case 'GET_CARD_FAIL':
            return {...state, message:action.payload.message,status:false};
        case 'GET_CARD_EXCEPTION':
            return {...state, message:action.payload,status:false};
        case 'GET_CARD_SUCCESS':
            return {...state, data:action.payload.data, status:true };
        default:
            return state;
    }
};