import React,{useState,useEffect} from 'react';



const Main = () => {


    return(
        <div className="vh-100">
            <div className="container h-100" style={{maxWidth:'360px'}}>
                <div className="row align-items-center justify-contain-center" style={{height:'90%'}}>
                    <div className="col-xl-12">
                         <img width="300" src="/LIVEEZ-Final-Logo.png" />
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Main;