const init = {
    status:false,
    message:'',
    data:{}
}

export const mountReducer = (state = init, action) => {
    switch (action.type){
        case 'USER_MOUNT_START':
            return {...state, status:false};
        case 'USER_MOUNT_FAIL':
            return {...state, status:false};
        case 'USER_MOUNT_SUCCESS':
            return {...state, status:true };
        default:
            return state;
    }
};