import Api from '../Api';

const useAuthIngest = async (url,params) => {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.post(url,params);
    return resp.data


}

export default useAuthIngest;


