import { React } from 'react';

const Footer = ({changeFooter}) => {

  let d = new Date();
  return (
    <div className={`footer ${changeFooter}`}>
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a style={{color:"#f60f14"}} href="https://www.liveez.lk" target="_blank" rel="noreferrer">
            Liveez
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
