const init = {
    message:'',
    data:{},
    status:false
}

export const roleReducer = (state = init, action) => {
    switch (action.type){
        case 'GET_ROLE_START':
            return state;
        case 'GET_ROLE_FAIL':
            return {...state, message:action.payload, data:false};
        case 'GET_ROLE_SUCCESS':
            return {...state, message:'', data:action.payload.data};
        default:
            return state;
    }
};