import React,{useState,useEffect,useContext} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import profile from "../../images/profile/profile.png";
import { Button,Tab, Nav } from "react-bootstrap";
import useGetData from "../../hooks/useGetData"
import useIngest from "../../hooks/useIngest"
import VCard from 'vcard-creator'
import ToastAlert from "../components/Magpie/ToastAlert";
import ContactModel from "../components/bootstrap/Models/ProfileContact";
import { ThemeContext } from "../../context/ThemeContext";
import { RWebShare } from "react-web-share";

const Profile = () => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState(false);
    const [errorMsg, setErrorMsg] = useState(true);
    const [showModel, setShowModel] = useState(false);
    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [fired,setFired] = useState(false);
    const [userId,setUserId] = useState(0);
    const { changePrimaryColor,changeBackground  } = useContext(ThemeContext);
    const [btnLoad, setBtnLoad] = useState(false);

    let url = window.location.pathname
    let aCode = url.split("/");
    let code = typeof aCode[2] !== 'undefined' ? aCode[2] : "";
    const [token, setToken] = useState(code);

    useEffect(() => {
        setTimeout( async () => {
            if(code.length === 0 ){
                navigate("/page-error-500");
            }else{
                HandleProfileData()
            }
        }, 10);
    }, []);

    const HandleActions = async (trackerId,card_id) => {
        await useIngest('/tracker',{tracker_id: trackerId, card_id:card_id});
    }

    const HandleProfileData = async () => {
        setErrorMsg(false)
        setBtnLoad(true)
        const respond = await useGetData('/get-profile',{profileCode:token});
        if(respond.status){
            setProfileData(respond.data[0])
            setUserId(respond.data[0].id)
            let theme = respond.data[0].theme_color !== null ? respond.data[0].theme_color.replace("Theme", "color") : 'color_1'
            changePrimaryColor(theme)
            changeBackground('light')
            HandleActions(1,respond.data[0].id)
        }else{
            setErrorMsg(true)
        }
        setBtnLoad(false)
    }

    const toBase64 = filePath => new Promise(async (resolve, reject) => {
        let file = await fetch(filePath).then(r => r.blob());
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    const downloadVcfFile = (vcfText,name) => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = name+".vcf";
        document.body.appendChild(element);
        element.click();
    };

    const genarateVcard = async (profileData) => {
        // const fs = require('fs')
        const myVCard = new VCard()
        const lastname = ''
        const firstname = profileData.card_name
        const additional = ''
        const prefix = ''
        const suffix = ''
        let fileBlob = await toBase64(profileData.image)

        myVCard
        // Add personal data
            .addName(lastname, firstname, additional, prefix, suffix)
            // Add work data
            .addCompany(profileData.organization_name)
            .addJobtitle(profileData.designation)
            // .addRole('Data Protection Officer')
            .addEmail(profileData.email)
            .addPhoneNumber(profileData.mobile_number, 'PREF;WORK')
            // .addPhoneNumber(123456789, 'WORK')
            .addAddress(profileData.organization_Address_1, profileData.organization_Address_2, null, null, null, null, null)
            // .addSocial(facebook, 'Twitter', 'desloovere_j')
            .addURL(profileData.organization_web)
            .addPhoto(fileBlob, 'JPEG')

        downloadVcfFile(myVCard.toString(),firstname)
        HandleActions(2,userId)
    }

    const handleModel = () => {
        setShowModel(!showModel)
    }

    const HandleRegistration = async (values) => {
        setFired(false)
        values.user_id = userId
        const respond = await useIngest('/send-card',values);
        setMessage(respond.message)
        setStatus(respond.status)
        setFired(true)
        if(respond.status){
            HandleActions(3,userId)
            handleModel()
        }
    }





    return(
        <div className="vh-100" style={{backgroundColor:profileData.background_color}}>
            {
                fired ? <ToastAlert message={message} status={status} /> : ""
            }
            <div className="container h-100" style={{maxWidth:'360px'}}>
                <div className="row align-items-center justify-contain-center" style={{height: '90%'}}>
                    {
                        !btnLoad ?
                            <div className="col-xl-12">
                                {
                                    !errorMsg ?
                                        <div className="card card-shadow overflow-hidden">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <div className="profile-photo">
                                                        <img
                                                            src={profileData.image === null ? profile : profileData.image}
                                                            width="100"
                                                            className="img-fluid rounded-circle"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <h3 className="mt-4 mb-1">{profileData.card_name}</h3>
                                                    <p className="text-muted te">{profileData.designation}</p>
                                                    <div>
                                                        <Button className="me-2" variant="primary btn-rounded" onClick={()=>genarateVcard(profileData)}>
                                                            Save Contact
                                                        </Button>

                                                        <Button className="me-2" variant="primary btn-rounded" onClick={handleModel} >
                                                            Connect
                                                        </Button>

                                                        <RWebShare
                                                            data={{
                                                                text: profileData.card_name,
                                                                url: url,
                                                                title: "Share",
                                                            }}
                                                            onClick={() =>
                                                                HandleActions(4,userId)
                                                            }
                                                        >
                                                            <i className="fa fa-solid fa-share btn me-2 text-primary mb-0" style={{fontSize:'20px', padding:'5px'}} ></i>
                                                        </RWebShare>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-footer pt-0 pb-0 text-center">
                                                <div className="custom-tab-1">
                                                    <Tab.Container defaultActiveKey="About">
                                                        <Tab.Content className="pt-4 text-left">
                                                            <Tab.Pane id="about-me" eventKey="About">
                                                                <ul>
                                                                    <li className="profile-tab-align-left">
                                                                        <h6 className="fa fa-phone" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.mobile_number}
                                                                    </li>
                                                                    <li className="profile-tab-align-left mt-3">
                                                                        <h6 className="fa fa-envelope" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.email}
                                                                    </li>
                                                                    <li className="profile-tab-align-left mt-3">
                                                                        <h6 className="fa fa-globe" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.organization_web}
                                                                    </li>
                                                                    <li className="profile-tab-align-left mt-3">
                                                                        <h6 className="fa fa-building" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.organization_Address_1 +' '+ profileData.organization_Address_2}
                                                                    </li>
                                                                </ul>
                                                                <div className="mt-3">
                                                                    {
                                                                        profileData.linkedin !== null ? <a href="profileData.linkedin"> <h5 className="m-3 mb-0 mt-0 fab fa-linkedin-in"></h5></a> : ""
                                                                    }
                                                                    {
                                                                        profileData.facebook !== null ? <a href="profileData.facebook"> <h5 className="m-3 mb-0 mt-0 fab fa-facebook-f"></h5></a> : ""
                                                                    }
                                                                    {
                                                                        profileData.youtube !== null ? <a href="profileData.youtube"> <h5 className="m-3 mb-0 mt-0  fab fa-youtube"></h5></a> : ""
                                                                    }
                                                                    {
                                                                        profileData.twitter !== null ? <a href="profileData.twitter"> <h5 className="m-3 mb-0 mt-0 fab fa-twitter"></h5></a> : ""
                                                                    }
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane id="profile-settings" eventKey="Setting">
                                                                <h4>{profileData.organization_name}</h4>
                                                                <p>{profileData.organization_description}</p>
                                                                <ul>
                                                                    <li className="profile-tab-align-left mt-3">
                                                                        <h6 className="fa fa-phone" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.organization_mobile_number}
                                                                    </li>
                                                                    <li className="profile-tab-align-left mt-3">
                                                                        <h6 className="fa fa-envelope" style={{marginRight:'15px'}} aria-hidden="true"> </h6>
                                                                        {profileData.organization_email}
                                                                    </li>
                                                                </ul>
                                                                <div className="mt-3">
                                                                    {
                                                                        profileData.organization_linkedin !== null ? <a href="profileData.organization_linkedin"><h5 className="m-3 mb-0 mt-0 fab fa-linkedin-in"></h5> </a>: ""
                                                                    }
                                                                    {
                                                                        profileData.organization_facebook !== null ? <a href="profileData.organization_facebook"><h5 className="m-3 mb-0 mt-0 fab fa-facebook-f"></h5> </a> : ""
                                                                    }
                                                                    {
                                                                        profileData.organization_youtube !== null ? <a href="profileData.organization_youtube"><h5 className="m-3 mb-0 mt-0  fab fa-youtube"></h5> </a> : ""
                                                                    }
                                                                    {
                                                                        profileData.organization_twitter !== null ? <a href="profileData.organization_twitter"><h5 className="m-3 mb-0 mt-0 fab fa-twitter"></h5> </a> : ""
                                                                    }
                                                                </div>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                        <Nav as='ul' className="nav-tabs profile-tab mt-4">
                                                            <Nav.Item as='li' className="nav-item">
                                                                <Nav.Link to="#about-me" eventKey='About'>ABOUT</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item as='li' className="nav-item">
                                                                <Nav.Link to="#profile-settings"
                                                                          eventKey='Setting'>COMPANY</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Tab.Container>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <h2 className="text-danger">ERROR 404</h2>
                                }
                            </div>
                            :
                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                    }

                </div>
            </div>
            <ContactModel isShow={showModel} hideFunc={handleModel} title="Connect" submitFunction={HandleRegistration} />
        </div>
    );

}

export default connect()(Profile);