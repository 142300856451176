import { lazy, Suspense, useEffect, useContext } from 'react';
/// Components
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams, Outlet } from 'react-router-dom';
/// Style
import './other/swiper/css/swiper-bundle.min.css';
import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./jsx/index.css";
import "./jsx/chart.css";
import "./jsx/step.css";

/// Layout
import Nav from "./jsx/layouts/nav";
import Footer from "./jsx/layouts/Footer";
import ScrollToTop from "./jsx/layouts/ScrollToTop";
import WalletBar from './jsx/layouts/WalletBar';
/// Dashboard
import Home from "./jsx/components/Dashboard/Home";
import Users from "./jsx/pages/User";

/// Pages
import LockScreen from "./jsx/pages/LockScreen";
import Error400 from "./jsx/pages/Error400";
import Error403 from "./jsx/pages/Error403";
import Error404 from "./jsx/pages/Error404";
import Error500 from "./jsx/pages/Error500";
import Error503 from "./jsx/pages/Error503";
import Setting from "./jsx/layouts/Setting";
import { ThemeContext } from "./context/ThemeContext";
import Login from "./jsx/pages/Login";
import EmailVerification from "./jsx/pages/emailVerification";
import {loginSync} from "./store/actions/login.action";
import {userMount} from "./store/actions/mount.action";
import UserProfile from "./jsx/pages/UserProfile";
import ForgotPassword from "./jsx/pages/ForgotPassword";
import ResetPassword from "./jsx/pages/ResetPassword";
import Cards from "./jsx/pages/Cards";
import Profile from "./jsx/pages/Profile";
import Organizations from "./jsx/pages/Organizations";
import Main from "./jsx/pages/Main";
import Profile2 from "./jsx/pages/Profile2";
import useGetData from "./hooks/useGetData"

function App ({loginFunc,userMountFunc,login,mount}) {

    let isLogin =  localStorage.getItem("isLogin");
    let first_name =  localStorage.getItem("first_name");
    let last_name =  localStorage.getItem("last_name");
    let user_id =  localStorage.getItem("user");
    let user_role =  localStorage.getItem("user_role");

    const { changePrimaryColor,changeBackground  } = useContext(ThemeContext);

    useEffect(() => {
        setTimeout( async () => {
            if(user_id !== null){
                if((/true/).test(isLogin)){
                    loginFunc()
                }
            }else{
                userMountFunc()
            }
        }, 5);
    }, []);

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                setTimeout(()=>{
                    GetTheme()
                },1000)
            }
        }
    }, [login,mount]);

    const GetTheme = async () => {
        const respond = await useGetData('/get-org');
        changePrimaryColor(respond.data)
    }


    // const allRoutes = [
    //     { url: 'users', component: <Users/> },
    //     { url: 'content', component: <Content/> },
    //     { url: "app-profile", component: <UserProfile/> },
    //     { url: "post-details", component: <PostDetails/> },
    //     { url: "uc-select2", component: <Select2/> },
    //     { url: "widget", component: <Widget/> },
    //     { url: "table-bootstrap-basic", component: <BootstrapTable/> },
    // ];

    return (
        <>
            <Suspense fallback={
                <div id="preloader">
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>
               }
            >
                <Routes>
                    <Route path='/page-error-400' element={<Error400 />} />
                    <Route path='/page-error-403' element={<Error403 />} />
                    <Route path='/page-error-404' element={<Error404 />} />
                    <Route path='/page-error-500' element={<Error500 />} />
                    <Route path='/page-error-503' element={<Error503 />} />
                    <Route path='/page-lock-screen' element={<LockScreen />} />
                    <Route path='/login' exact element={<Login/>} />
                    <Route path='/email-verification/:user' exact element={<EmailVerification/>} />
                    <Route path='/email-verification' exact element={<EmailVerification/>} />
                    <Route path='/forgot-password' exact element={<ForgotPassword/>} />
                    <Route path='/confirm-password' exact element={<ResetPassword/>} />
                    <Route path='/confirm-password/:user' exact element={<ResetPassword/>} />
                    <Route path='/profile/:user' exact element={<Profile2/>} />
                    <Route path='/profile' exact element={<Profile2/>} />
                    {/*<Route path='/profile2' exact element={<Profile/>} />*/}
                    {/*<Route path='/profile2/:user' exact element={<Profile/>} />*/}
                    <Route path='/' exact element={<Main/>} />
                    <Route element={<Layout1 />}>
                        <Route path='/admin' exact element={<Home/>} />
                        <Route path='/admin/users' exact element={<Users/>} />
                        <Route path='/admin/cards' exact element={<Cards/>} />
                        <Route path='/admin/organizations' exact element={<Organizations/>} />
                        <Route path='/admin/profile' exact element={<UserProfile/>} />
                        {/*<Route path='/dashboard' exact element={<Home/>} />*/}
                        {/*<Route path='/header-theme' exact element={<Theme1/>} />*/}
                        {/*{ allRoutes.map((data, i) => (*/}
                            {/*<Route key={i} exact path={`/${data.url}`} element={data.component} />*/}
                        {/*))}*/}
                        {/*<Route path='/ui-button' exact element={<UiButton/>} />*/}
                        {/*<Route path='/ui-card' exact element={<UiCards/>} />*/}
                        {/*<Route path='/widget' exact element={<Widget/>} />*/}
                        {/*<Route path='/ui-tab' exact element={<UiTab/>} />*/}
                        {/*<Route path='/ui-pagination' exact element={<UiPagination/>} />*/}
                        {/*<Route path='/ui-modal' exact element={<UiModal/>} />*/}
                        {/*<Route path='/edit-profile' exact element={<EditProfile/>} />*/}
                        {/*<Route path='/uc-sweetalert' exact element={<MainSweetAlert/>} />*/}
                        {/*<Route path='/uc-toastr' exact element={<Toastr/>} />*/}
                    </Route>

                </Routes>
                {/*<Setting/>*/}
                <ScrollToTop />
                {/*<Index />*/}
            </Suspense>
        </>
    );
};

function Layout1(){
    const { menuToggle, sidebariconHover } = useContext(ThemeContext);
    return(

        <div id="main-wrapper" className={`wallet-open active show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>
            <Nav />
            <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                    <Outlet />
                    <Footer />
                </div>
            </div>
            <WalletBar />
            <div className="wallet-bar-close"></div>
        </div>

    )
}

const initMapDispatchToProps = dispatch => ({
    loginFunc: (data,tkn) => dispatch(loginSync(data,tkn)),
    userMountFunc: () => dispatch(userMount()),

})

const mapStateToProps = state => ({
    login: state.auth,
    mount: state.mount
})

export default connect(mapStateToProps,initMapDispatchToProps)(App);