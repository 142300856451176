import Api from '../../Api';

export const getOrganizationStart = () => ({
    type:"GET_ORGANIZATION_START"
})

export const getOrganizationSuccess = payload => ({
    type:"GET_ORGANIZATION_SUCCESS",
    payload
})

export const getOrganizationFail = payload => ({
    type:"GET_ORGANIZATION_FAIL",
    payload
})

export const getOrganizationException = payload => ({
    type:"GET_ORGANIZATION_EXCEPTION",
    payload
})


export const  getOrganizationsAsync = () => async dispatch => {
    dispatch(getOrganizationStart())
    try {
        const aData = await getOrganizations()
        if(aData.status){
            dispatch(getOrganizationSuccess(aData))
        }
        else
            dispatch(getOrganizationFail(aData))

    }catch (e){
        dispatch(getOrganizationException(e.message))
    }
};

export const  getActiveOrganizationsAsync = () => async dispatch => {
    dispatch(getOrganizationStart())
    try {
        const aData = await getActiveOrganizations()
        if(aData.status){
            dispatch(getOrganizationSuccess(aData))
        }
        else
            dispatch(getOrganizationFail(aData))

    }catch (e){
        dispatch(getOrganizationException(e.message))
    }
};

async function getOrganizations() {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.get('organizations');
    return resp.data
}

async function getActiveOrganizations() {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.get('active-organizations');
    return resp.data
}