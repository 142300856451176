import React, { Fragment, useEffect,useState,useReducer } from "react";
import {connect} from "react-redux";
import {Row,Col,Card,Table,Button,Modal,Dropdown} from "react-bootstrap";
import ToastAlert from "../components/Magpie/ToastAlert";
import CustomSweetAlert from "../components/Magpie/SweerAlert";
import {useNavigate} from 'react-router-dom';
import {getUsersAsync} from "../../store/actions/get-users.action";
import PagePagination from "../components/Magpie/PagePagination";
import useAuthIngest from "../../hooks/useAuthIngest"
import {getRoleAsync} from "../../store/actions/role.action";
import avatar1 from "../../images/avatar/1.jpg";
import UserCreateModel from "../components/bootstrap/Models/UserCreate";
import UserUpdateModel from "../components/bootstrap/Models/UserUpdate";
import UserRoleModel from "../components/bootstrap/Models/UserRole";
import {getActiveOrganizationsAsync} from "../../store/actions/organization.action";


const Users = ({getUsersFunc,users,roleFunc,roles,login,mount,getOrgFunc,org}) => {

    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [fired,setFired] = useState(false);
    const [showCreateModel, setShowCreateModel] = useState(false);
    const [showUpdateModel, setShowUpdateModel] = useState(false);
    const [showRoleModel, setShowRoleModel] = useState(false);
    const [userData, setUserData] = useState({});
    const [userId, setUserId] = useState(0);
    const [peginateData, setPeginateData] = useState({});
    const navigate = useNavigate();
    const [initiate, setInitiate] = useState(1);
    const [btnLoad, setBtnLoad] = useState(false);
    const [scrHeight, setScrHeight] = useState('0px');

    const  HandleDimension = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let avilHeight = Math.ceil((height/2))+'px'
        setScrHeight(height)
    }

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                roleFunc()
                getOrgFunc()
                getUsersFunc()
                HandleDimension()            }
            else{
                navigate("/login");
            }
        }
    }, [login,mount]);

    useEffect(() => {
        if(users.status){
            peginate(1,10)
        }
    }, [users]);

    const handleCreateModel = () => {
        setBtnLoad(false)
        setShowCreateModel(!showCreateModel)
    }

    const handleUpdateModel = (row) => {
        setUserId(row.id)
        setUserData(row)
        setBtnLoad(false)
        setShowUpdateModel(!showUpdateModel)
    }

    const handleRoleModel = (row) => {
        setUserId(row.id)
        setUserData(row)
        setBtnLoad(false)
        setShowRoleModel(!showRoleModel)
    }

    const HandleRegistration  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        const respond = await useAuthIngest('/registration',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            HandleSendOTP(respond.data)
            getUsersFunc()
            handleCreateModel()
        }
    }

    const HandleSendOTP = async (id) => {
        setFired(false)
        const respond = await useAuthIngest('email-resend-code',{user_id:id});
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
    }

    const HandleUserUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.user_id = userId;
        const respond = await useAuthIngest('/user-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getUsersFunc()
            setShowUpdateModel(!showUpdateModel)
        }
    }

    const HandleRoleUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.user_id = userId;
        const respond = await useAuthIngest('/user-role-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getUsersFunc()
            setShowRoleModel(!showRoleModel)
        }
    }

    const HandleStatusUpdate  = async (values) =>{
        setFired(false)
        values.user_id = values.id;
        const respond = await useAuthIngest('/user-status-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getUsersFunc()
        }
    }

    const HandleUserDelete  = async (values) =>{
        setFired(false)
        values.user_id = values.id;
        const respond = await useAuthIngest('/user-delete',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getUsersFunc()
        }
    }

    const peginate = (page, perPage) => {
        let data = [];
        if(users.status && users.data.length > 0){
            let st = (page - 1) * perPage
            let chunk = page * perPage
            data =  users.data.slice(st, chunk);
        }
        setPeginateData(data)
    }

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    return (
        <Fragment>
            <Row>
                {
                    fired ? <ToastAlert message={message} status={status} /> : ""
                }
                <Col lg={12} style={{minHeight:scrHeight}}>
                    <Card>
                        <Card.Header>
                            <Card.Title>User Manage</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Button className="me-2"
                                    variant="primary"
                                    onClick={handleCreateModel}
                            >
                                <i className="fa fa-user me-2" />New User
                            </Button>
                            <div className="mb-2"></div>
                            {
                                users.status ?
                                    <Table responsive className="header-border">
                                        <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>User Role</th>
                                            <th>Email</th>
                                            <th>Contact Number</th>
                                            <th>Last Logging Date</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            peginateData.length > 0 ?
                                                peginateData.map((row,i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={row.image === null ? avatar1 : row.image}
                                                                    className="rounded-lg me-2"
                                                                    width="24"
                                                                    alt=""
                                                                />{" "}
                                                                <span className="w-space-no">{row.first_name+" "+row.last_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{row.role_name}</td>
                                                        <td>{row.email}</td>
                                                        <td>{row.mobile_number}</td>
                                                        <td>{row.last_logged_time}</td>
                                                        <td>{
                                                            row.status_id === 0 ? "New User"
                                                                : (row.status_id === 1 ? "Active"
                                                                : (row.status_id === 2 ? "Deactivated" : "Deleted"))
                                                        }</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="warning"
                                                                    className="light sharp i-false"
                                                                >
                                                                    {svg1}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={()=>handleUpdateModel(row)} >Edit</Dropdown.Item>
                                                                    {
                                                                        row.status_id > 0 ?
                                                                            <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleStatusUpdate(row)} command={row.status_id === 1 ? 'Deactivate' : 'Activate'} bodyMsg={row.status_id === 1 ? 'Change User Status to De Active' : 'Change User Status to Active'} cancelMsg="Fail, User Status Not Change" okMsg={message}  /> : ""
                                                                    }
                                                                    <Dropdown.Item onClick={()=>handleRoleModel(row)} >Role Manage</Dropdown.Item>
                                                                    {
                                                                        row.status_id === 0 || row.status_id === 2 ?
                                                                            <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleUserDelete(row)} command='Delete' bodyMsg='Delete User' cancelMsg="Fail, User not deleted" okMsg={message}  />  : ""
                                                                    }
                                                                    <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleSendOTP(row.id)} command='Resend OTP' bodyMsg='Resend OTP' cancelMsg="Fail, User not resend OTP" okMsg={message}  />
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                            :
                                            <tr>
                                                <td>&nbsp;</td>
                                            </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    : ""
                            }

                            {
                                users.status && users.data.length > 0 ?
                                    <div className="pt-2 m-l5">
                                        <PagePagination dataCount={users.data.length} perPage="10" pegiFunction={peginate} />
                                    </div> : ""
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <UserCreateModel isShow={showCreateModel} hideFunc={handleCreateModel} title="New User" submitFunction={HandleRegistration} aRoles={roles.data} aOrgs={org} btnLoad={btnLoad} />
            <UserRoleModel isShow={showRoleModel} hideFunc={handleRoleModel} title="Role Manage" submitFunction={HandleRoleUpdate} aRoles={roles.data} aData={userData} btnLoad={btnLoad} />
            <UserUpdateModel isShow={showUpdateModel} hideFunc={handleUpdateModel} title="Update User" submitFunction={HandleUserUpdate} aData={userData} btnLoad={btnLoad} />
        </Fragment>
    );

}

const initMapStateToProps = state => ({
    users: state.users,
    login: state.auth,
    roles: state.roles,
    operation: state.operation,
    mount: state.mount,
    org: state.org
})

const initMapDispatchToProps = dispatch => ({
    getUsersFunc: () => dispatch(getUsersAsync()),
    roleFunc: () => dispatch(getRoleAsync()),
    getOrgFunc: () => dispatch(getActiveOrganizationsAsync()),
})

export default connect(initMapStateToProps,initMapDispatchToProps) (Users)