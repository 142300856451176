import React, { Fragment, useEffect,useState } from "react";
import {connect} from "react-redux";
import {Row,Col,Card,Table,Button,Dropdown} from "react-bootstrap";
import ToastAlert from "../components/Magpie/ToastAlert";
import CustomSweetAlert from "../components/Magpie/SweerAlert";
import {useNavigate} from 'react-router-dom';
import PagePagination from "../components/Magpie/PagePagination";
import useAuthIngest from "../../hooks/useAuthIngest"
import {getOrganizationsAsync} from "../../store/actions/organization.action";
import CardSocialUpdateModel from "../components/bootstrap/Models/CardUpdateSocial";
import OrgLogoUpload from "../components/bootstrap/Models/OrgLogoUpload";
import OrgCreateModel from "../components/bootstrap/Models/OrgCreate";
import OrgUpdateModel from "../components/bootstrap/Models/OrgUpdate";
import OrgThemeManageModel from "../components/bootstrap/Models/OrgThemeManage";
import { useColor } from "react-color-palette";
import "react-color-palette/css";
import OrgUploadModel from "../components/bootstrap/Models/OrgFileUpload";
import OrgBGModel from "../components/bootstrap/Models/OrgBgManage";


const Organizations = ({getOrganizationFunc,login,mount,org}) => {

    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [fired,setFired] = useState(false);
    const [showCreateModel, setShowCreateModel] = useState(false);
    const [showUpdateModel, setShowUpdateModel] = useState(false);
    const [showSocialUpdateModel, setShowSocialUpdateModel] = useState(false);
    const [showThemeModel, setShowThemeModel] = useState(false);
    const [showBgModel, setShowBgModel] = useState(false);
    const [showFileUploadModel, setShowFileUploadModel] = useState(false);
    const [showLogoUploadModel, setShowLogoUploadModel] = useState(false);
    const [orgData, setOrgData] = useState({});
    const [orgId, setOrgId] = useState(0);
    const [peginateData, setPeginateData] = useState({});
    const navigate = useNavigate();
    const [uploadFile, setUploadFile] = useState({});
    const [themeColor, setThemeColor] = useState('Theme_01');
    const [bgColor, setBgColor] = useColor("#561ecb");
    const [btnLoad, setBtnLoad] = useState(false);
    const [scrHeight, setScrHeight] = useState('0px');

    const  HandleDimension = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let avilHeight = Math.ceil((height/2))+'px'
        setScrHeight(height)
    }
    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                getOrganizationFunc()
                HandleDimension()
            }
            else{
                navigate("/login");
            }
        }
    }, [login,mount]);

    useEffect(() => {
        if(org.status){
            peginate(1,10)
        }
    }, [org]);

    const handleCreateModel = () => {
        setBtnLoad(false)
        setShowCreateModel(!showCreateModel)
    }

    const handleUpdateModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setBtnLoad(false)
        setShowUpdateModel(!showUpdateModel)
    }

    const handleSocialUpdateModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setBtnLoad(false)
        setShowSocialUpdateModel(!showSocialUpdateModel)
    }

    const handleThemeModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setThemeColor(row.theme_color)
        setUploadFile({})
        setBtnLoad(false)
        setShowThemeModel(!showThemeModel)
    }

    const handleBgModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setUploadFile({})
        setBtnLoad(false)
        setShowBgModel(!showBgModel)
    }

    const handleFileUploadModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setUploadFile({})
        setBtnLoad(false)
        setShowFileUploadModel(!showFileUploadModel)
    }

    const handleLogoUploadModel = (row) => {
        setOrgId(row.id)
        setOrgData(row)
        setUploadFile({})
        setBtnLoad(false)
        setShowLogoUploadModel(!showLogoUploadModel)
    }

    const HandleRegistration  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        const respond = await useAuthIngest('/register-organization',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getOrganizationFunc()
            handleCreateModel()
        }
    }

    const HandleOrgUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.org_id = orgId;
        const respond = await useAuthIngest('/organization-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getOrganizationFunc()
            setShowUpdateModel(!showUpdateModel)
        }
    }

    const HandleSocialMediaUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.org_id = orgId;
        const respond = await useAuthIngest('/organization-social-media-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
            setShowSocialUpdateModel(!showSocialUpdateModel)
        }
        setBtnLoad(false)
    }

    const HandleThemeUpdate  = async () =>{
        if(typeof uploadFile.name !== "undefined" ){
            UploadThemeFileHandle()
        }else{
            HandleThemeDataUpdate()
        }
    }

    const HandleThemeDataUpdate = async () => {
        setFired(false)
        setBtnLoad(true)
        const respond = await useAuthIngest('/organization-theme-update',{org_id:orgId, theme_color:themeColor});
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
            setShowThemeModel(!showThemeModel)
        }
        setBtnLoad(false)
    }

    const HandleBackGroundDataUpdate = async () => {
        setFired(false)
        setBtnLoad(true)
        const respond = await useAuthIngest('/organization-bg-update',{org_id:orgId, bg_color:bgColor.hex});
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
            setShowBgModel(!showBgModel)
        }
        setBtnLoad(false)
    }

    const UploadThemeFileHandle = async () => {
        setFired(false)
        setBtnLoad(true)
        const body = new FormData();
        body.append("files", uploadFile);
        body.append("org_id", orgId);
        body.append("bg_color", bgColor.hex);
        body.append("theme_color", themeColor.hex);
        const respond = await useAuthIngest('/organization-theme-update',body);
        setFired(true)
        setBtnLoad(false)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
            setShowThemeModel(!showThemeModel)
        }
    }

    const HandleDesignFileUpload = async () => {
        setFired(false)
        setBtnLoad(true)
        const body = new FormData();
        body.append("files", uploadFile);
        body.append("org_id", orgId);
        const respond = await useAuthIngest('/organization-file-update',body);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getOrganizationFunc()
            setShowFileUploadModel(!showFileUploadModel)
        }
    }

    const HandleLogoFileUpload = async () => {
        setFired(false)
        setBtnLoad(true)
        const body = new FormData();
        body.append("files", uploadFile);
        body.append("org_id", orgId);
        const respond = await useAuthIngest('/organization-logo-update',body);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getOrganizationFunc()
            setShowLogoUploadModel(!showLogoUploadModel)
        }
    }

    const HandleStatusUpdate  = async (values) =>{
        setFired(false)
        values.org_id = values.id;
        const respond = await useAuthIngest('/organization-status-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
        }
    }

    const HandleDelete  = async (values) =>{
        setFired(false)
        values.org_id = values.id;
        const respond = await useAuthIngest('/organization-delete',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getOrganizationFunc()
        }
    }

    const peginate = (page, perPage) => {
        let data = [];
        if(org.status && org.data.length > 0){
            let st = (page - 1) * perPage
            let chunk = page * perPage
            data =  org.data.slice(st, chunk);
        }
        setPeginateData(data)
    }

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    const handleUpload = (e) => {
        setUploadFile(e.currentTarget.files[0])
    }

    const themeFunction = (v) => {
        setThemeColor(v)
    }

    return (
        <Fragment>
            <Row>
                {
                    fired ? <ToastAlert message={message} status={status} /> : ""
                }
                <Col lg={12} style={{minHeight:scrHeight}}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Organization Manage</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Button className="me-2"
                                    variant="primary"
                                    onClick={handleCreateModel}
                            >
                                <i className="fa fa-id-card me-2" />New Organization
                            </Button>
                            <div className="mb-2"></div>
                            {
                                org.status ?
                                    <Table responsive className="header-border">
                                        <thead>
                                        <tr>
                                            <th>Organization</th>
                                            <th>Theme</th>
                                            <th>BG Color</th>
                                            {/*<th>BG Image</th>*/}
                                            <th>Social</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            peginateData.length > 0 ?
                                                peginateData.map((row,i) => (
                                                    <tr key={i}>
                                                        <td>{row.name}</td>
                                                        <td>{row.theme_color}</td>
                                                        <td style={{backgroundColor:row.background_color}}></td>
                                                        {/*<td><img src={row.background_image} alt={row.name} /> </td>*/}
                                                        <td>
                                                            {
                                                                row.linkedin !== null ? <i className="m-1 fab fa-linkedin-in"></i> : ""
                                                            }
                                                            {
                                                                row.facebook !== null ? <i className="m-1 fab fa-facebook-f"></i> : ""
                                                            }
                                                            {
                                                                row.youtube !== null ? <i className="m-1 fab fa-youtube"></i> : ""
                                                            }
                                                            {
                                                                row.twitter !== null ? <i className="m-1 fab fa-twitter"></i> : ""
                                                            }

                                                        </td>
                                                        <td>{
                                                            row.status_id === 1 ? "Active" : "De Activated"
                                                        }</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="warning"
                                                                    className="light sharp i-false"
                                                                >
                                                                    {svg1}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={()=>handleUpdateModel(row)} >Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleSocialUpdateModel(row)} >Social Media</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleThemeModel(row)} >Theme Manage</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleBgModel(row)} >BG Manage</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleFileUploadModel(row)} >File Upload</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleLogoUploadModel(row)} >Logo Upload</Dropdown.Item>
                                                                    <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleStatusUpdate(row)} command={row.status_id === 1 ? 'Deactivate' : 'Activate'} bodyMsg={row.status_id === 1 ? 'Change Organization Status to De Active' : 'Change Organization Status to Active'} cancelMsg="Fail, Organization Status Not Change" okMsg={message}  />
                                                                    {
                                                                        row.status_id === 2 ?
                                                                            <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleDelete(row)} command='Delete' bodyMsg='Delete Organization' cancelMsg="Fail, Organization not deleted" okMsg={message}  />  : null
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    : ""
                            }

                            {
                                org.status && org.data.length > 0 ?
                                    <div className="pt-2 m-l5">
                                        <PagePagination dataCount={org.data.length} perPage="10" pegiFunction={peginate} />
                                    </div> : ""
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <OrgCreateModel isShow={showCreateModel} hideFunc={handleCreateModel} title="New Organization" submitFunction={HandleRegistration} btnLoad={btnLoad} />
            <OrgUploadModel isShow={showFileUploadModel} hideFunc={handleFileUploadModel} title="Card File Design Upload" submitFunction={HandleDesignFileUpload} handleUpload={handleUpload} btnLoad={btnLoad} />
            <OrgLogoUpload isShow={showLogoUploadModel} hideFunc={handleLogoUploadModel} title="Company Logo" submitFunction={HandleLogoFileUpload} handleUpload={handleUpload} btnLoad={btnLoad} />
            <OrgUpdateModel isShow={showUpdateModel} hideFunc={handleUpdateModel} title="Update Organization" submitFunction={HandleOrgUpdate} oData={orgData} btnLoad={btnLoad} />
            <CardSocialUpdateModel isShow={showSocialUpdateModel} hideFunc={handleSocialUpdateModel} title="Update Social Media" submitFunction={HandleSocialMediaUpdate} oData={orgData} btnLoad={btnLoad} />
            <OrgThemeManageModel isShow={showThemeModel} hideFunc={handleThemeModel} title="Theme Manage" submitFunction={HandleThemeUpdate} themeFunction={themeFunction} thm={themeColor} btnLoad={btnLoad} />
            <OrgBGModel isShow={showBgModel} hideFunc={handleBgModel} title="Background Manage" submitFunction={HandleBackGroundDataUpdate} handleBgColor={setBgColor} bgColor={bgColor} btnLoad={btnLoad}  />
        </Fragment>
    );

}

const initMapStateToProps = state => ({
    login: state.auth,
    mount: state.mount,
    org: state.org,
})

const initMapDispatchToProps = dispatch => ({
    getOrganizationFunc: () => dispatch(getOrganizationsAsync())
})

export default connect(initMapStateToProps,initMapDispatchToProps) (Organizations)