import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import Input from "../../Magpie/Input";
import TextArea from "../../Magpie/TextArea";

const userSchema = Yup.object().shape({
    org_name: Yup.string()
        .required("Please provide a organization name"),
    email: Yup.string()
        .required("Please enter a username")
        .email('Please provide valid email'),
    mobile_number: Yup.string()
        .required("Please provide mobile number"),
    address1: Yup.string()
        .required("Please provide a address"),
    description: Yup.string()
        .required("Please provide a description")


});

const OrgUpdateModel = ({isShow,hideFunc,title,submitFunction,oData, btnLoad}) => {

    return (
        <Modal className="fade bd-example-modal-lg"  backdrop="static"  show={isShow} size="lg" onHide={hideFunc}>
            <Modal.Header>
                <Modal.Title><i className="fa fa-user me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        org_name: oData.name,
                        email: oData.email,
                        mobile_number: oData.mobile_number,
                        address1: oData.Address_1 === null ? '' : oData.Address_1,
                        address2: oData.Address_2 === null ? '' : oData.Address_2,
                        description: oData.description,
                        web:oData.web
                    }}
                    validationSchema={userSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="org_name"
                                        id="org_name"
                                        placeholder="Organization Name"
                                        errors={errors.org_name}
                                        value={values.org_name}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="web"
                                        id="web"
                                        placeholder="Website"
                                        errors={errors.web}
                                        value={values.web}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        errors={errors.email}
                                        value={values.email}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="mobile_number"
                                        id="mobile_number"
                                        placeholder="Contact Number"
                                        errors={errors.mobile_number}
                                        value={values.mobile_number}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <Input
                                        name="address1"
                                        id="address1"
                                        placeholder="Address"
                                        errors={errors.address1}
                                        value={values.address1}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <Input
                                        name="address2"
                                        id="address2"
                                        placeholder="Address Lane/City"
                                        errors={errors.address2}
                                        value={values.address2}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <TextArea
                                        name="description"
                                        id="description"
                                        placeholder="Description"
                                        errors={errors.description}
                                        value={values.description}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !btnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save changes
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }

                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default OrgUpdateModel
