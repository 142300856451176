import React, { useState,useContext } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import {Formik} from 'formik';
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";

const OrgBGModel = ({isShow,hideFunc,title,submitFunction,handleBgColor,bgColor, btnLoad}) => {

    return (
        <Modal className="fade bd-example-modal-lg"  backdrop="static"  show={isShow} size="lg" onHide={hideFunc}>
            <Modal.Header>
                <Modal.Title><i className="fa fa-user me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        profile_image:''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-md-6">
                                    <label>Background Color</label>
                                    <ColorPicker color={bgColor} onChange={handleBgColor} />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !btnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save changes
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }
                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default OrgBGModel
