import swal from "sweetalert";
import {
    Dropdown
} from "react-bootstrap";

const CustomSweetAlert = ({isSuccess,command,bodyMsg,cancelMsg,okMsg,actionFunc}) => {
    return (
        <Dropdown.Item
            onClick={() =>
                swal({
                    title: "Are you sure?",
                    text: bodyMsg,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((willDelete) => {
                    if (willDelete) {
                        actionFunc()
                        // if(isSuccess){
                        //     swal(okMsg, {
                        //         icon: "success",
                        //     });
                        // }else{
                        //     swal(okMsg, {
                        //         icon: "error",
                        //     });
                        // }
                    } else {
                        swal(cancelMsg,{
                            icon:"error"
                        });
                    }
                })
            }
            className="btn btn-warning btn sweet-confirm"
        >
            {command}
        </Dropdown.Item>
    );
};

export default CustomSweetAlert;
