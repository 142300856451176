import React,{useState, useEffect} from "react";
import { Link,useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import profile from "../../../images/user.jpg";
import alb from '../../../images/svg/albania.svg';
import {connect} from "react-redux";
import {logoutSync} from "../../../store/actions/login.action";
import useAuthGetData from "../../../hooks/useAuthGetData"

const Header = ({ login,mount,logoutFunc }) => {
    const [searchBut, setSearchBut] = useState(false);
    const [selectImage, setSelectImage] = useState([alb,'Albania']);
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();
    //For header fixed
    const [headerFix, setheaderFix] = useState(false);

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                setInterval(() => {
                    ValidateHandle()
                }, 2000000)
            }
        }
    },[login,mount])

    const ValidateHandle  = async () => {
        try {
            return await useAuthGetData('/validate');
        }
        catch(error) {
            if(error.response.status === 401){
                handleLogout()
            }
        }
    }

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                setUserData(login.data)
            }
        }
    }, [login,mount]);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});
	}, []);

    const handleLogout = () => {
        logoutFunc()
            .then(()=>{
                navigate("/login");
            })
    }
  
  return ( 
    <div className={`header ${ headerFix ? "sticky" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">

            </div>
			<ul className="navbar-nav header-right">
			    <li className="nav-item ">
					<Dropdown className="dropdown header-profile2">
						<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">							
							<div className="header-info2 d-flex align-items-center">
								<div className="d-flex align-items-center sidebar-info">
									<div>
										<h4 className="mb-0">
											{
                                                Object.keys(userData).length > 0 ?
													login.data.first_name + " " + login.data.last_name
													: ""
											}
										</h4>
										<span className="d-block text-end">
											{
                                                Object.keys(userData).length > 0  ?
                                                    login.data.role_type
                                                    : ""
                                            }
										</span>
									</div>
								</div>
								<img src={login.data.image === null ? profile : login.data.image}  alt="" />
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
							<Link to="/admin/profile" className="dropdown-item ai-icon">
								<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
									<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										<polygon points="0 0 24 0 24 24 0 24"/>
										<path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
										<path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="var(--primary)" fillRule="nonzero"/>
									</g>
								</svg>
								<span className="ms-2">Profile </span>
							</Link>
							<Link to="#" onClick={handleLogout} className="dropdown-item ai-icon">
								<svg
									id="icon-logout" xmlns="http://www.w3.org/2000/svg"
									className="text-danger me-1" width={18} height={18} viewBox="0 0 24 24"
									fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" fillRule="round"
								>
									<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
									<polyline points="16 17 21 12 16 7" />
									<line x1={21} y1={12} x2={9} y2={12} />
								</svg>
								<span className="ms-2">Logout </span>
							</Link>
						</Dropdown.Menu>
					</Dropdown>	
			    </li>  	
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};


const initMapDispatchToProps = dispatch => ({
    logoutFunc: () => dispatch(logoutSync())
})

const mapStateToProps = state => ({
    login: state.auth,
    mount: state.mount,
})

export default connect(mapStateToProps,initMapDispatchToProps)(Header);
