import React, { useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastAlert = ({message,status}) => {

    const notifySuccess = (message) => {

        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const notifyError = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    useEffect(() => {

        if(status){
            let msg = "✔️ "+message+" !"
            notifySuccess(msg)
        }
        else{
            let msg = "❌ "+message+" !"
            notifyError(msg)
        }


    }, [status]);

    return (
        <ToastContainer />
    )

}

export default ToastAlert



