/**
 * Created by rukshala on 11/29/24.
 */
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";


const QRModel = ({isShow,hideFunc,title,qr}) => {

    return (
        <Modal className="fade"  backdrop="static"  show={isShow} onHide={hideFunc} centered>
            <Modal.Header>
                <Modal.Title><i className="fa fa-envelope me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
               <img src={qr} />
            </Modal.Body>

        </Modal>
    )

}

export default QRModel
