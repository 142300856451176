import React from 'react';

const Checkbox = ({name,placeholder,id, errors, value, handleChange, values}) => {

    return (
        <div className="input-group wow fadeInUp mt-2" data-wow-delay="1.8s">
            <label className="form-check-label" style={{lineHeight:'25px'}}>
                <input
                    className="form-check-input m-1"
                    type="checkbox"
                    id={id}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    checked={values.indexOf(value.toString()) > -1}
                />
                {placeholder}
            </label>
            <div
                id={`${id}-error`}
                className="invalid-feedback animated fadeInUp p-0"
                style={{ display: "block" }}
            >
                {errors && errors}
            </div>
        </div>

    )

}

export default Checkbox



