import React, { Fragment, useEffect,useState } from "react";
import { Tab, Nav, Button } from "react-bootstrap";
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import profile from "../../images/profile/profile.png";
import PageTitle from "../layouts/PageTitle";
import {connect} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from "yup";
import Input from "../components/Magpie/Input";
import Password from "../components/Magpie/Password";
import FileUpload from "../components/Magpie/FileUpload";
import useAuthIngest from "../../hooks/useAuthIngest"
import ToastAlert from "../components/Magpie/ToastAlert";


const userSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("Please provide a first name")
        .max(100, "Provided name is too long"),
    last_name: Yup.string()
        .required("Please provide a last name")
        .max(100, "Provided name is too long"),
    mobile_number: Yup.string()
        .required("Please provide a mobile number")
});

const validateSchema = Yup.object().shape({
    current_password: Yup.string()
        .required("Please provide a current password"),
    new_password: Yup.string()
        .required("Please provide a new password"),
    confirm_password: Yup.string()
        .required("Please provide a confirm password")
        .oneOf([Yup.ref('new_password'), null], 'Confirm Passwords must match with Password'),
});

const UserProfile = ({login,mount}) => {

    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [fired,setFired] = useState(false);
    const [fileError, setFileError] = useState('');
    const [uploadFile, setUploadFile] = useState({});
    const [btnLoad1, setBtnLoad1] = useState(false);
    const [btnLoad2, setBtnLoad2] = useState(false);
    const [btnLoad3, setBtnLoad3] = useState(false);

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                setUserData(login.data)
            }
            else{
                navigate("/login");
            }
        }
    }, [login,mount]);

    const HandleUserUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad1(true)
        values.user_id = userData.id;
        const respond = await useAuthIngest('/user-info-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad1(false)
    }

    const HandlePasswordUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad2(true)
        values.user_id = userData.id;
        const respond = await useAuthIngest('/user-password-change',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad2(false)
    }

    const handleUpload = (e) => {
        setUploadFile(e.currentTarget.files[0])
    }

    const HandleImage = async () => {
        setFired(false)
        setBtnLoad3(true)
        const body = new FormData();
        uploadFile.name !== '' ? setFileError("") : setFileError("Please select file")
        body.append("files", uploadFile);
        const respond = await useAuthIngest('/upload-profile-image',body);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        document.querySelector('#profile_image').value = '';
        setBtnLoad3(false)
    }

    return (
        <Fragment>
            <PageTitle activeMenu="Profile" motherMenu="App" />
            {
                fired ? <ToastAlert message={message} status={status} /> : ""
            }
            {
                Object.keys(userData).length > 0 ?
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile card card-body px-3 pt-3 pb-0">
                                <div className="profile-head">
                                    <div className="profile-info">
                                        <div className="profile-photo" style={{marginTop:'-1.5rem'}}>
                                            <img src={userData.image === null ? profile : userData.image} className="img-fluid rounded-circle" alt="profile"/>
                                        </div>
                                        <div className="profile-details">
                                            <div className="profile-name px-3 pt-2">
                                                <h4 className="text-primary mb-0">{userData.first_name+' '+userData.last_name}</h4>
                                                <p>Name</p>
                                            </div>
                                            <div className="profile-email px-2 pt-2">
                                                <h4 className="text-muted mb-0">{userData.email}</h4>
                                                <p>Email</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
            {
                Object.keys(userData).length > 0 ?

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-tab">
                                        <div className="custom-tab-1">
                                            <Tab.Container defaultActiveKey='About'>
                                                <Nav as='ul' className="nav nav-tabs">
                                                    <Nav.Item as='li' className="nav-item">
                                                        <Nav.Link to="#about-me" eventKey='About'>About Me</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item as='li' className="nav-item">
                                                        <Nav.Link to="#profile-settings" eventKey='Setting'>Setting</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane id="about-me" eventKey='About'>
                                                        <div className="profile-about-me">
                                                            <div className="pt-4 border-bottom-1 pb-3">
                                                                <p className="mb-2">{userData.first_name + " " + userData.last_name}</p>
                                                                <p className="mb-2">{userData.email}</p>
                                                                <p className="mb-2">{userData.mobile_number}</p>
                                                                <p className="mb-2">{userData.role_type}</p>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane id="profile-settings" eventKey='Setting'>
                                                        <div className="row">
                                                            <div className="col-md-6 col-xl-6">
                                                                <h4 className="mt-2 mb-0">Personal Information</h4>
                                                                <Formik
                                                                    initialValues={{
                                                                        first_name: userData.first_name,
                                                                        last_name: userData.last_name,
                                                                        mobile_number: userData.mobile_number
                                                                    }}
                                                                    enableReinitialize={true}
                                                                    validationSchema={userSchema}
                                                                    onSubmit={(values, { setSubmitting }) => {
                                                                        setTimeout(() => {
                                                                            HandleUserUpdate(values)
                                                                            setSubmitting(false);
                                                                        }, 400);
                                                                    }}

                                                                >
                                                                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                                                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    <Input
                                                                                        name="first_name"
                                                                                        id="first_name"
                                                                                        placeholder="First Name"
                                                                                        errors={errors.first_name}
                                                                                        value={values.first_name}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    <Input
                                                                                        name="last_name"
                                                                                        id="last_name"
                                                                                        placeholder="Last Name"
                                                                                        errors={errors.last_name}
                                                                                        value={values.last_name}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    <Input
                                                                                        name="mobile_number"
                                                                                        id="mobile_number"
                                                                                        placeholder="Contact Number"
                                                                                        errors={errors.mobile_number}
                                                                                        value={values.mobile_number}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    {
                                                                                        !btnLoad1 ?
                                                                                            <Button
                                                                                                variant=""
                                                                                                type="submit"
                                                                                                className="btn btn-primary"
                                                                                            >
                                                                                                Save changes
                                                                                            </Button>
                                                                                            :
                                                                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                            <div className="col-md-6 col-xl-6">
                                                                <h4 className="mt-2 mb-0">Password Change</h4>
                                                                <Formik
                                                                    initialValues={{
                                                                        current_password: '',
                                                                        new_password: '',
                                                                        confirm_password: ''
                                                                    }}
                                                                    enableReinitialize={true}
                                                                    validationSchema={validateSchema}
                                                                    onSubmit={(values, { setSubmitting }) => {
                                                                        setTimeout(() => {
                                                                            HandlePasswordUpdate(values)
                                                                            setSubmitting(false);
                                                                        }, 400);
                                                                    }}

                                                                >
                                                                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                                                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    <Password
                                                                                        name="current_password"
                                                                                        id="current_password"
                                                                                        placeholder="Current Password"
                                                                                        errors={errors.current_password}
                                                                                        value={values.current_password}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-md-12">
                                                                                    <Password
                                                                                        name="new_password"
                                                                                        id="new_password"
                                                                                        placeholder="New Password"
                                                                                        errors={errors.new_password}
                                                                                        value={values.new_password}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-xl-12">
                                                                                    <Password
                                                                                        name="confirm_password"
                                                                                        id="confirm_password"
                                                                                        placeholder="Confirm Password"
                                                                                        errors={errors.confirm_password}
                                                                                        value={values.confirm_password}
                                                                                        handleChange={handleChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="row ">
                                                                                <div className="col-xl-12">
                                                                                    {
                                                                                        !btnLoad2 ?
                                                                                            <Button
                                                                                                variant=""
                                                                                                type="submit"
                                                                                                className="btn btn-primary"
                                                                                            >
                                                                                                Save Password
                                                                                            </Button>
                                                                                            :
                                                                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </form>
                                                                    )}
                                                                </Formik>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <h4 className="mt-2 mb-0">Profile Image</h4>
                                                            <Formik
                                                                initialValues={{
                                                                    profile_image: ''
                                                                }}
                                                                enableReinitialize={true}
                                                                onSubmit={(values, { setSubmitting }) => {
                                                                    setTimeout(() => {
                                                                        HandleImage(values)
                                                                        setSubmitting(false);
                                                                    }, 400);
                                                                }}

                                                            >
                                                                {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                                                    <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                                                        <div className="row ">
                                                                            <div className="col-xl-12">
                                                                                <FileUpload
                                                                                    name="profile_image"
                                                                                    id="profile_image"
                                                                                    errors={fileError}
                                                                                    placeholder="Upload Profile Picture"
                                                                                    handleChange={handleUpload}
                                                                                />
                                                                            </div>
                                                                            {Object.keys(uploadFile).length > 0 ? uploadFile.name : ''}
                                                                        </div>
                                                                        <div className="row ">
                                                                            <div className="col-xl-12">
                                                                                {
                                                                                    !btnLoad3 ?
                                                                                        <Button
                                                                                            variant=""
                                                                                            type="submit"
                                                                                            className="btn btn-primary"
                                                                                        >
                                                                                            Upload Image
                                                                                        </Button>
                                                                                        :
                                                                                        <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </Formik>
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ""
            }
        </Fragment>
    );
};

const initMapStateToProps = state => ({
    login: state.auth,
    mount: state.mount,
})

export default connect(initMapStateToProps) (UserProfile)