const init = {
    status:false,
    message:'',
    data:{}
}

export const getUsersReducer = (state = init, action) => {
    switch (action.type){
        case 'GET_USER_START':
            return state;
        case 'GET_USER_FAIL':
            return {...state, message:action.payload.message,status:false};
        case 'GET_USER_EXCEPTION':
            return {...state, message:action.payload,status:false};
        case 'GET_USER_SUCCESS':
            return {...state, data:action.payload.data, status:true };
        default:
            return state;
    }
};