import Api from '../../Api';

export const permissionStart = () => ({
    type:"PERMISSION_START"
})

export const permissionSuccess = payload => ({
    type:"PERMISSION_SUCCESS",
    payload
})

export const permissionFail = payload => ({
    type:"PERMISSION_FAIL",
    payload
})


export const  getPermissionAsync = () => async dispatch => {
    dispatch(permissionStart())
    try {
        const aData = await getPermission()
        if(aData.status){
            dispatch(permissionSuccess(aData))
        }
        else
            dispatch(permissionFail(aData))

    }catch (e){
        dispatch(permissionFail(e.message))
    }
};

async function getPermission() {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.post('permissions',);
    return resp.data
}