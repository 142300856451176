export const mountStart = () => ({
    type:"USER_MOUNT_START"
})

export const mountSuccess = payload => ({
    type:"USER_MOUNT_SUCCESS",
    payload
})

export const mountFail = payload => ({
    type:"USER_MOUNT_FAIL",
    payload
})

export const  userMount = (values) => async dispatch => {
    dispatch(mountStart())
    try {
        dispatch(mountSuccess())
    }catch (e){
        dispatch(mountFail(e.message))
    }
};

