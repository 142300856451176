import Api from '../../Api';

export const loginStart = () => ({
    type:"LOGIN_START"
})

export const loginSuccess = payload => ({
    type:"LOGIN_SUCCESS",
    payload
})

export const loginFail = payload => ({
    type:"LOGIN_FAIL",
    payload
})

export const loginUnAuth = payload => ({
    type:"LOGIN_UN_AUTH",
    payload
})

export const mountSuccess = payload => ({
    type:"USER_MOUNT_SUCCESS",
    payload
})

export const  loginAsync = (oData) => async dispatch => {
    dispatch(loginStart())
    try {
        if(oData.status){
            localStorage.setItem("isLogin", true);
            localStorage.setItem("user", oData.data.id);
            localStorage.setItem("first_name", oData.data.first_name);
            localStorage.setItem("last_name", oData.data.last_name);
            localStorage.setItem("user_role", oData.data.role_type);
            localStorage.setItem("token", oData.token);
            localStorage.setItem("attempt",1)
            dispatch(loginSuccess(oData))
        }
        else
            dispatch(loginFail(oData.message))
    }catch (e){
        dispatch(loginUnAuth(e.message))
    }
};

export const  loginSync = (data,tkn) => async dispatch => {
    dispatch(loginStart())
    try {

        Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        const resp = await Api.post('/jwtlogin',data);
        const aData = resp.data
        if(aData.status){
            localStorage.setItem("isLogin", true);
            localStorage.setItem("user", aData.data.id);
            localStorage.setItem("first_name", aData.data.first_name);
            localStorage.setItem("first_name", aData.data.last_name);
            localStorage.setItem("user_role", aData.data.role_type);
            localStorage.setItem("token", aData.token);
            dispatch(loginSuccess(aData))
            dispatch(mountSuccess())
        }
        else
            dispatch(loginFail("session time out"))

    }catch (e){
        dispatch(mountSuccess())
        dispatch(loginUnAuth(e.message))
    }
};

export const  logoutSync = () => async dispatch => {
    dispatch(loginStart())
    try {

        Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
        const resp = await Api.post('logout');
        const aData = resp.data
        if(aData.status){
            localStorage.removeItem("isLogin");
            localStorage.removeItem("first_name");
            localStorage.removeItem("last_name");
            localStorage.removeItem("user");
            localStorage.removeItem("user_role");
            localStorage.removeItem("token");
            Api.defaults.headers.common['Authorization'] = '';
            dispatch(loginFail("Session log out"))
        }
        else
            dispatch(loginFail("session time out"))

    }catch (e){
        dispatch(loginFail(e.message))
    }
};