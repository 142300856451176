import React from 'react';

const FileUpload = ({name,placeholder,id, errors, value, handleChange}) => {

    return (
        <div>
            <label className="mb-0 mt-2">{placeholder}</label>
            <div className="input-group wow fadeInUp mt-2" data-wow-delay="1.8s">
                <input
                    name={name}
                    type="file"
                    id={id}
                    className="form-control"
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value}
                />
                <div
                    id={`${id}-error`}
                    className="invalid-feedback animated fadeInUp p-0"
                    style={{ display: "block" }}
                >
                    {errors && errors}
                </div>
            </div>
        </div>

    )

}

export default FileUpload



