import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Input from "../components/Magpie/Input";
import Password from "../components/Magpie/Password";
import * as Yup from "yup";
import {Formik} from 'formik';
import {connect} from 'react-redux';
import ToastAlert from "../components/Magpie/ToastAlert";
import useIngest from "../../hooks/useIngest"

const validateSchema = Yup.object().shape({
    verification_code: Yup.string()
        .required("Please provide a verification code")
        .min(5,"Please provide valid verification code"),
    new_password: Yup.string()
        .required("Please provide a password"),
    confirm_password: Yup.string()
        .required("Please provide a confirm password")
        .oneOf([Yup.ref('new_password'), null], 'Confirm Passwords must match with Password'),
});

const ResetPassword = (props) => {
    const navigate = useNavigate();
    const { login } = props;
    let url = window.location.pathname
    let aCode = url.split("/");
    let code = typeof aCode[2] !== 'undefined' ? aCode[2] : "";
    const [token, setToken] = useState(code);
    const [fired, setFired] = useState(false);
    const [message, setMessage] = useState(false);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            if(code.length === 0 )
                navigate("/page-error-500");
        }, 10);
    }, []);

    useEffect(() => {
        if(login.isLogin)
            navigate("/admin");
    }, [login]);

    const HandleFormSubmit = async (data) => {
        setFired(false)
        data.user_id = token;
        const respond = await useIngest('/reset-password',data);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            setTimeout(()=>{
                navigate("/login");
            },3000)
        }
    }

    return(
        <div className="vh-100">
            <div className="container h-100">
                {
                    fired ? <ToastAlert message={message} status={status} /> : ""
                }
                <div className="row h-100 align-items-center justify-contain-center">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="row m-0">
                                    <div className="col-xl-6 col-md-6 sign text-center">
                                        <div>
                                            {
                                                <img style={{marginTop:'100px'}} src="/LIVEEZ-Final-Logo.png" className=" slideskew img-fix bitcoin-img sd-shape7" />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-md-6" >
                                        <div className="sign-in-your px-2">
                                            <h4 className="fs-20 ">Reset Password</h4>
                                            <Formik
                                                initialValues={{
                                                    verification_code: "",
                                                    new_password: "",
                                                    confirm_password: "",
                                                }}
                                                validationSchema={validateSchema}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setTimeout(() => {
                                                        HandleFormSubmit(values)
                                                        setSubmitting(false);
                                                    }, 400);
                                                }}

                                            >
                                                {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                                    <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                                        <div className="row ">
                                                            <div className="col-md-12 mb-2">
                                                                <Input
                                                                    name="verification_code"
                                                                    id="verification_code"
                                                                    placeholder="Enter OTP"
                                                                    errors={errors.verification_code}
                                                                    value={values.verification_code}
                                                                    handleChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-md-12 mb-2">
                                                                <Password
                                                                    name="new_password"
                                                                    id="new_password"
                                                                    placeholder="Password"
                                                                    errors={errors.new_password}
                                                                    value={values.new_password}
                                                                    handleChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-md-12 mb-2">
                                                                <Password
                                                                    name="confirm_password"
                                                                    id="confirm_password"
                                                                    placeholder="Confirm Password"
                                                                    errors={errors.confirm_password}
                                                                    value={values.confirm_password}
                                                                    handleChange={handleChange}
                                                                />
                                                            </div>
                                                            <div className="col-md-12 p-t20">
                                                                <button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Reset Password</button> &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}



const initMapStateToProps = state => ({
    login: state.auth
})

export default connect(initMapStateToProps)(ResetPassword);