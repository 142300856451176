import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import Input from "../../Magpie/Input";
import InputDropdown from "../../Magpie/dropdown";
import FileUpload from "../../Magpie/FileUpload";

const userSchema = Yup.object().shape({
    org_id: Yup.number()
        .required("Please select organization"),
    display_name: Yup.string()
        .required("Please provide a name"),
    designation: Yup.string()
        .required("Please provide a designation"),
    email: Yup.string()
        .required("Please enter a username")
        .email('Please provide valid email'),
    mobile_number: Yup.string()
        .required("Please provide mobile number")
});

const CardCreateModel = ({isShow,hideFunc,title,submitFunction,aOrgs,handleUpload,btnLoad}) => {

    const [fileError, setFileError] = useState('');

    return (
        <Modal className="fade bd-example-modal-lg"  backdrop="static"  show={isShow} size="lg" onHide={hideFunc}>
            <Modal.Header>
                <Modal.Title><i className="fa fa-user me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        org_id: "",
                        display_name: "",
                        designation: "",
                        email: "",
                        mobile_number: "",
                        linkedIn: "",
                        youtube: "",
                        facebook: "",
                        twitter: "",
                    }}
                    validationSchema={userSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-md-12">
                                    <InputDropdown
                                        name="org_id"
                                        id="org_id"
                                        placeholder="Select Organization"
                                        errors={errors.org_id}
                                        aData={aOrgs}
                                        handleChange={handleChange}
                                        selectValue={values.org_id}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="display_name"
                                        id="display_name"
                                        placeholder="Name"
                                        errors={errors.display_name}
                                        value={values.display_name}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="designation"
                                        id="designation"
                                        placeholder="Designation"
                                        errors={errors.designation}
                                        value={values.designation}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        errors={errors.email}
                                        value={values.email}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="mobile_number"
                                        id="mobile_number"
                                        placeholder="Contact Number"
                                        errors={errors.mobile_number}
                                        value={values.mobile_number}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="linkedIn"
                                        id="linkedIn"
                                        placeholder="LinkedIn"
                                        errors={errors.linkedIn}
                                        value={values.linkedIn}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="youtube"
                                        id="youtube"
                                        placeholder="YouTube"
                                        errors={errors.youtube}
                                        value={values.youtube}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="facebook"
                                        id="facebook"
                                        placeholder="Facebook"
                                        errors={errors.facebook}
                                        value={values.facebook}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="twitter"
                                        id="twitter"
                                        placeholder="Twitter"
                                        errors={errors.twitter}
                                        value={values.twitter}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-xl-12">
                                    <FileUpload
                                        name="profile_image"
                                        id="profile_image"
                                        errors={fileError}
                                        placeholder="Upload Picture (300X300)"
                                        handleChange={handleUpload}
                                    />
                                </div>
                            </div>

                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !btnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save changes
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }
                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default CardCreateModel
