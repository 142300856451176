const init = {
    isLogin:false,
    message:'',
    data:{},
    status: false,
    token:"",
    unauth:false
}

export const loginReducer = (state = init, action) => {
    switch (action.type){
        case 'LOGIN_START':
            return state;
        case 'LOGIN_FAIL':
            return {...state, message:action.payload, isLogin:false, status:false};
        case 'LOGIN_SUCCESS':
            return {...state, message:'', isLogin:true, data:action.payload.data, token:action.payload.token };
        case 'LOGIN_UN_AUTH':
            return {...state, message:action.payload, isLogin:false, unauth:true};
        default:
            return state;
    }
};