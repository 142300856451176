const init = {
    status:false,
    message:'',
    data:{}
}

export const operationReducer = (state = init, action) => {
    switch (action.type){
        case 'USER_OPERATION_START':
            return state;
        case 'USER_OPERATION_FAIL':
            return {...state, message:action.payload.message,status:false};
        case 'USER_OPERATION_SUCCESS':
            return {...state, data:action.payload.data, status:true };
        default:
            return state;
    }
};