import Api from '../../Api';

export const getUsersStart = () => ({
    type:"GET_USER_START"
})

export const getUsersSuccess = payload => ({
    type:"GET_USER_SUCCESS",
    payload
})

export const getUsersFail = payload => ({
    type:"GET_USER_FAIL",
    payload
})

export const getUsersException = payload => ({
    type:"GET_USER_EXCEPTION",
    payload
})


export const  getUsersAsync = () => async dispatch => {
    dispatch(getUsersStart())
    try {
        const aData = await getUsers()
        if(aData.status){
            dispatch(getUsersSuccess(aData))
        }
        else
            dispatch(getUsersFail(aData))

    }catch (e){
        dispatch(getUsersException(e.message))
    }
};

async function getUsers() {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.get('user-manage/users-data',);
    return resp.data
}