import React,{useState, useContext, useEffect, useReducer} from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";
import HomeSlider from './Dashboard/HomeSlider';
import {connect} from "react-redux";
import {useNavigate} from 'react-router-dom';
import useAuthGetData from "../../../hooks/useAuthGetData"

const EmailPieChart = loadable(() =>
 	pMinDelay(import("./Dashboard/EmailPieChart"), 1000)
);

const Home = ({mount,login}) => {
	const { changeBackground } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [kpiData, setKpiData] = useState([]);
    const [kpiDataPer, setKpiDataPer] = useState([]);
    const [topViewers, setTopViewers] = useState({});
    const [topDownloads, setTopDownloads] = useState({});

	// useEffect(() => {
	// 	changeBackground({ value: "dark", label: "Dark" });
	// }, []);

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
            	setTimeout(() => {
                    LoadDashboardData()
				},1000)
            }
            else{
                navigate("/login");
            }
        }
    }, [login,mount]);

    const LoadDashboardData = async () => {
        setKpiData([])
        const rsp = await useAuthGetData('/dashboard')
		if(rsp[0].status){
        	let respond = rsp[0]
            const loadProfile = respond.data.filter( x => x.tracker_id === 1);
            const downloadProfile = respond.data.filter( x => x.tracker_id === 2);
            const mailProfile = respond.data.filter( x => x.tracker_id === 3);
            const shareProfile = respond.data.filter( x => x.tracker_id === 4);
			const countSum = respond.data.reduce((a, b) => +a + +b.card_count, 0);
            setKpiData(
            	[
                    loadProfile.length > 0 ? loadProfile[0].card_count : 0,
                    downloadProfile.length > 0 ? downloadProfile[0].card_count : 0,
                    mailProfile.length > 0 ? mailProfile[0].card_count : 0,
                    shareProfile.length > 0 ? shareProfile[0].card_count : 0,
				]
			)

            setKpiDataPer(
                [
                    loadProfile.length > 0 ? ((loadProfile[0].card_count/countSum) * 100).toFixed(2) : 0,
                    downloadProfile.length > 0 ? ((downloadProfile[0].card_count/countSum) * 100).toFixed(2) : 0,
                    mailProfile.length > 0 ? ((mailProfile[0].card_count/countSum) * 100).toFixed(2) : 0,
                    shareProfile.length > 0 ? ((shareProfile[0].card_count/countSum) * 100).toFixed(2) : 0,
                ]
            )
		}

        if(rsp[1].status){
            let respond = rsp[1]
            setTopViewers(respond.data)
        }

        if(rsp[2].status){
            let respond = rsp[2]
            setTopDownloads(respond.data)
        }
	}

	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="page-titles style1">
								<div className="d-flex align-items-center">
									<h2 className="heading">Dashboard</h2>
								</div>
							</div>
						</div>
					</div>
					<HomeSlider kpiData={kpiData} />
					<div className="row">
						<div className="col-xl-4 wow fadeInUp" data-wow-delay="1s">
							<div className="card">
								<div className="row m-3">
									<h4>Top Profiles</h4>
									{
										Object.keys(topViewers).length > 0 ?
											topViewers.map((r,i) => (
												<div className="row">
													<div className="col-xl-12">
														<table width="100%">
															<tbody>
																<tr>
																	<td className="pt-2">{r.card_name}</td>
																	<td  className="pt-2" style={{textAlign:'right'}} >{r.card_count}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											))

											:
											""
									}
								</div>
							</div>
						</div>
						<div className="col-xl-4 wow fadeInUp" data-wow-delay="1s">
							<div className="card">
								<div className="row m-3">
									<h4>Top Downloads</h4>
                                    {
                                        Object.keys(topDownloads).length > 0 ?
                                            topDownloads.map((r,i) => (
												<div className="row">
													<div className="col-xl-12">
														<table width="100%">
															<tbody>
															<tr>
																<td className="pt-2">{r.card_name}</td>
																<td  className="pt-2" style={{textAlign:'right'}} >{r.card_count}</td>
															</tr>
															</tbody>
														</table>
													</div>
												</div>
                                            ))

                                            :
                                            ""
                                    }
								</div>
							</div>
						</div>
						<div className="col-xl-4 wow fadeInUp" data-wow-delay="1s">
							<div className="card">
								<div className="card-body text-center pt-0 pb-2">
									<EmailPieChart kpiData={kpiData} />
									<div className="chart-items">											
										<div className="row">
											<div className=" col-xl-12 col-sm-12">
												<div className="text-start mt-2">
													<span className="font-w600 mb-3 d-block text-black fs-14">Legend</span>
													<div className="color-picker">
														<span className="mb-0 col-6 fs-14">
															<svg className="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect width="14" height="14" rx="4" fill="#9568FF" />
															</svg>
															View Profile
														</span>
														<h5>{kpiDataPer[0]}%</h5>
													</div>
													<div className="color-picker">
														<span className="mb-0 col-6 fs-14">
															<svg className="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect width="14" height="14" rx="4" fill="#FF5166"/>
															</svg>
															Download
														</span>
														<h5>{kpiDataPer[1]}%</h5>
													</div>
													<div className="color-picker">
														<span className="mb-0 col-6 fs-14">
															<svg className="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect width="14" height="14" rx="4" fill="#ED3DD1"/>
															</svg>
															Mail
														</span>
														<h5>{kpiDataPer[2]}%</h5>
													</div>
													<div className="color-picker">
														<span className="mb-0 col-6 fs-14">
															<svg className="me-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
																<rect width="14" height="14" rx="4" fill="#2BC844"/>
															</svg>
															Share
														</span>
														<h5>{kpiDataPer[3]}%</h5>
													</div>	
												</div>
											</div>												
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>	
				</div>
			</div>		
		</>
	)
}

const initMapStateToProps = state => ({
    login: state.auth,
    mount: state.mount,
})

export default connect(initMapStateToProps) (Home)