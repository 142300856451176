import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import thunk from 'redux-thunk';
import {loginReducer} from "./reducers/login.reducer";
import {getUsersReducer} from "./reducers/getUsers.reducer";
import {roleReducer} from "./reducers/role.reducer";
import {operationReducer} from "./reducers/operation.reducer";
import {mountReducer} from "./reducers/mount.reducer";
import {getCardsReducer} from "./reducers/get-card.reducer";
import {getOrganizationsReducer} from "./reducers/organization.reducer";
import {permissionReducer} from "./reducers/permission.reducer";
const middlewareRepos = [thunk]
const middleware = applyMiddleware(...middlewareRepos);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    auth: loginReducer,
    users: getUsersReducer,
    roles: roleReducer,
    operation: operationReducer,
    mount: mountReducer,
    cards: getCardsReducer,
    org: getOrganizationsReducer,
    permission: permissionReducer,
});

export const store = createStore(reducers,  composeEnhancers(middleware));
