import Api from '../Api';

const useGetData = async (url,params) => {

    const resp = await Api.post(url,params);
    return resp.data


}

export default useGetData;


