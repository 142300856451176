import React, { Fragment, useEffect,useState } from "react";
import {connect} from "react-redux";
import {Row,Col,Card,Table,Button,Dropdown} from "react-bootstrap";
import ToastAlert from "../components/Magpie/ToastAlert";
import CustomSweetAlert from "../components/Magpie/SweerAlert";
import {useNavigate} from 'react-router-dom';
import PagePagination from "../components/Magpie/PagePagination";
import useAuthIngest from "../../hooks/useAuthIngest"
import {getCardsAsync} from "../../store/actions/get-cards.action";
import CardCreateModel from "../components/bootstrap/Models/CardCreate";
import {getActiveOrganizationsAsync} from "../../store/actions/organization.action";
import CardUpdateModel from "../components/bootstrap/Models/CardUpdate";
import CardSocialUpdateModel from "../components/bootstrap/Models/CardUpdateSocial";
import CardImageUploadModel from "../components/bootstrap/Models/CardImageUpload";
import avatar1 from "../../images/avatar/1.jpg";
import QRModel from "../components/bootstrap/Models/LoadQR";

const Cards = ({getCardFunc,login,mount,cards,getOrganizationFunc,org}) => {

    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);
    const [fired,setFired] = useState(false);
    const [showCreateModel, setShowCreateModel] = useState(false);
    const [showQRModel, setShowQRModel] = useState(false);
    const [showUpdateModel, setShowUpdateModel] = useState(false);
    const [showSocialUpdateModel, setShowSocialUpdateModel] = useState(false);
    const [showFileUploadModel, setShowFileUploadModel] = useState(false);
    const [cardData, setCardData] = useState({});
    const [cardId, setCardId] = useState(0);
    const [peginateData, setPeginateData] = useState({});
    const navigate = useNavigate();
    const [uploadFile, setUploadFile] = useState({});
    const [btnLoad, setBtnLoad] = useState(false);
    const [scrHeight, setScrHeight] = useState('0px');

    const  HandleDimension = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let avilHeight = Math.ceil((height/2))+'px'
        setScrHeight(height)
    }

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                getCardFunc()
                getOrganizationFunc()
                HandleDimension()
            }
            else{
                navigate("/login");
            }
        }
    }, [login,mount]);

    useEffect(() => {
        if(cards.status){
            peginate(1,10)
        }
    }, [cards]);

    const handleCreateModel = () => {
        setUploadFile({})
        setBtnLoad(false)
        setShowCreateModel(!showCreateModel)
    }

    const handleUpdateModel = (row) => {
        setCardId(row.id)
        setCardData(row)
        setBtnLoad(false)
        setShowUpdateModel(!showUpdateModel)
    }

    const handleSocialUpdateModel = (row) => {
        setCardId(row.id)
        setCardData(row)
        setBtnLoad(false)
        setShowSocialUpdateModel(!showSocialUpdateModel)
    }

    const handleFileUploadModel = (row) => {
        setCardId(row.id)
        setCardData(row)
        setUploadFile({})
        setBtnLoad(false)
        setShowFileUploadModel(!showFileUploadModel)
    }

    const handleQRModel = (row) => {
        setCardId(row.id)
        setCardData(row)
        setBtnLoad(false)
        setShowQRModel(!showQRModel)
    }

    const HandleRegistration  = async (values) =>{
        if(typeof uploadFile.name === "undefined" ){
            HandleRegisterData(values)
        }else{
            HandleImage(values)
        }
    }

    const HandleRegisterData = async (values) => {
        setFired(false)
        setBtnLoad(true)
        const respond = await useAuthIngest('/register-card',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getCardFunc()
            handleCreateModel()
        }
    }

    const HandleImage = async (values) => {
        setFired(false)
        setBtnLoad(true)
        const body = new FormData();
        body.append("files", uploadFile);
        body.append("org_id", values.org_id);
        body.append("display_name", values.display_name);
        body.append("designation", values.designation);
        body.append("email", values.email);
        body.append("mobile_number", values.mobile_number);
        body.append("linkedIn", values.linkedIn);
        body.append("youtube", values.youtube);
        body.append("facebook", values.facebook);
        body.append("twitter", values.twitter);
        const respond = await useAuthIngest('/register-card',body);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getCardFunc()
            handleCreateModel()
        }
    }

    const HandleCardUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.card_id = cardId;
        const respond = await useAuthIngest('/card-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            getCardFunc()
            setShowUpdateModel(!showUpdateModel)
        }
    }

    const HandleSocialMediaUpdate  = async (values) =>{
        setFired(false)
        setBtnLoad(true)
        values.card_id = cardId;
        const respond = await useAuthIngest('/card-social-media-update',values);
        setFired(true)
        setBtnLoad(false)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getCardFunc()
            setShowSocialUpdateModel(!showSocialUpdateModel)
        }
    }

    const HandleFileUpload  = async () =>{
        if(typeof uploadFile.name !== "undefined" ){
            UploadFileHandle()
        }
    }

    const UploadFileHandle = async () => {
        setFired(false)
        setBtnLoad(true)
        const body = new FormData();
        body.append("files", uploadFile);
        body.append("card_id", cardId);
        const respond = await useAuthIngest('/upload-card-image',body);
        setFired(true)
        setBtnLoad(false)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getCardFunc()
            setShowFileUploadModel(!showFileUploadModel)
        }
    }

    const HandleStatusUpdate  = async (values) =>{
        setFired(false)
        values.card_id = values.id;
        const respond = await useAuthIngest('/card-status-update',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getCardFunc()
        }
    }

    const HandleUserDelete  = async (values) =>{
        setFired(false)
        values.card_id = values.id;
        const respond = await useAuthIngest('/card-delete',values);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            getCardFunc()
        }
    }

    const peginate = (page, perPage) => {
        let data = [];
        if(cards.status && cards.data.length > 0){
            let st = (page - 1) * perPage
            let chunk = page * perPage
            data =  cards.data.slice(st, chunk);
        }
        setPeginateData(data)
    }

    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );

    const handleUpload = (e) => {
        setUploadFile(e.currentTarget.files[0])
    }

    return (
        <Fragment>
            <Row>
                {
                    fired ? <ToastAlert message={message} status={status} /> : ""
                }
                <Col lg={12} style={{minHeight:scrHeight}}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Card Manage</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Button className="me-2"
                                    variant="primary"
                                    onClick={handleCreateModel}
                            >
                                <i className="fa fa-id-card me-2" />New Card
                            </Button>
                            <div className="mb-2"></div>
                            {
                                cards.status ?
                                    <Table responsive className="header-border">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>Organization</th>
                                            <th>Card Name</th>
                                            <th>Contact Number</th>
                                            <th>Email</th>
                                            <th>Designation</th>
                                            <th>Social</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            peginateData.length > 0 ?
                                                peginateData.map((row,i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={row.image === null ? avatar1 : row.image}
                                                                    className="rounded-lg me-2"
                                                                    width="24"
                                                                    alt=""
                                                                />{" "}
                                                            </div>
                                                        </td>
                                                        <td>{row.organization_name}</td>
                                                        <td>{row.card_name}</td>
                                                        <td>{row.mobile_number}</td>
                                                        <td>{row.email}</td>
                                                        <td>{row.designation}</td>
                                                        <td>
                                                            {
                                                                row.linkedin !== null ? <i className="m-1 fab fa-linkedin-in"></i> : ""
                                                            }
                                                            {
                                                                row.facebook !== null ? <i className="m-1 fab fa-facebook-f"></i> : ""
                                                            }
                                                            {
                                                                row.youtube !== null ? <i className="m-1 fab fa-youtube"></i> : ""
                                                            }
                                                            {
                                                                row.twitter !== null ? <i className="m-1 fab fa-twitter"></i> : ""
                                                            }

                                                        </td>
                                                        <td>{
                                                            row.status_id === 1 ? "Active" : "De Activated"
                                                        }</td>
                                                        <td>
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="warning"
                                                                    className="light sharp i-false"
                                                                >
                                                                    {svg1}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item onClick={()=>handleQRModel(row)} >QR</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleUpdateModel(row)} >Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleSocialUpdateModel(row)} >Social Media</Dropdown.Item>
                                                                    <Dropdown.Item onClick={()=>handleFileUploadModel(row)} >File Upload</Dropdown.Item>
                                                                    <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleStatusUpdate(row)} command={row.status_id === 1 ? 'Deactivate' : 'Activate'} bodyMsg={row.status_id === 1 ? 'Change Card Status to De Active' : 'Change Card Status to Active'} cancelMsg="Fail, Card Status Not Change" okMsg={message}  />
                                                                    {
                                                                        row.status_id === 2 ?
                                                                            <CustomSweetAlert isSuccess={isSuccess}  actionFunc={()=>HandleUserDelete(row)} command='Delete' bodyMsg='Delete Card' cancelMsg="Fail, Card not deleted" okMsg={message}  />  : null
                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))
                                                :
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                    : ""
                            }

                            {
                                cards.status && cards.data.length > 0 ?
                                    <div className="pt-2 m-l5">
                                        <PagePagination dataCount={cards.data.length} perPage="10" pegiFunction={peginate} />
                                    </div> : ""
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <CardCreateModel isShow={showCreateModel} hideFunc={handleCreateModel} title="New Card" submitFunction={HandleRegistration} aOrgs={org.data} handleUpload={handleUpload} btnLoad={btnLoad} />
            <QRModel isShow={showQRModel} hideFunc={handleQRModel} title="QR CODE" qr={'/qrcode/'+cardData.id+'-'+cardData.org_id+'.png'} btnLoad={btnLoad} />
            <CardImageUploadModel isShow={showFileUploadModel} hideFunc={handleFileUploadModel} title="Profile Image Upload" submitFunction={HandleFileUpload} handleUpload={handleUpload} btnLoad={btnLoad} />
            <CardUpdateModel isShow={showUpdateModel} hideFunc={handleUpdateModel} title="Update Card" submitFunction={HandleCardUpdate} aOrgs={org.data} oData={cardData} btnLoad={btnLoad} />
            <CardSocialUpdateModel isShow={showSocialUpdateModel} hideFunc={handleSocialUpdateModel} title="Update Social Media" submitFunction={HandleSocialMediaUpdate} oData={cardData} btnLoad={btnLoad} />
        </Fragment>
    );

}

const initMapStateToProps = state => ({
    login: state.auth,
    mount: state.mount,
    cards: state.cards,
    org: state.org,
})

const initMapDispatchToProps = dispatch => ({
    getCardFunc: () => dispatch(getCardsAsync()),
    getOrganizationFunc: () => dispatch(getActiveOrganizationsAsync()),
})

export default connect(initMapStateToProps,initMapDispatchToProps) (Cards)