import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import Input from "../../Magpie/Input";
import InputDropdown from "../../Magpie/dropdown";
import Checkbox from "../../Magpie/Checkbox";

const userSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("Please provide a first name")
        .max(100, "Provided name is too long"),
    last_name: Yup.string()
        .required("Please provide a last name")
        .max(100, "Provided name is too long"),
    mobile_number: Yup.string()
        .required("Please provide a mobile number"),
    email: Yup.string()
        .required("Please enter a username")
        .email('Please provide valid email'),
    role_id: Yup.number()
        .required("Please select role")
});

const UserCreateModel = ({isShow,hideFunc,title,submitFunction,aRoles,aOrgs,btnLoad}) => {

    return (
        <Modal className="fade bd-example-modal-lg"  backdrop="static"  show={isShow} size="lg" onHide={hideFunc}>
            <Modal.Header>
                <Modal.Title><i className="fa fa-user me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        first_name: "",
                        last_name: "",
                        email: "",
                        mobile_number: "",
                        role_id: "",
                        orgs: []
                    }}
                    validationSchema={userSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="first_name"
                                        id="first_name"
                                        placeholder="First Name"
                                        errors={errors.first_name}
                                        value={values.first_name}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name"
                                        errors={errors.last_name}
                                        value={values.last_name}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-6">
                                    <Input
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        errors={errors.email}
                                        value={values.email}
                                        handleChange={handleChange}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        name="mobile_number"
                                        id="mobile_number"
                                        placeholder="Contact Number"
                                        errors={errors.mobile_number}
                                        value={values.mobile_number}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <InputDropdown
                                        name="role_id"
                                        id="role_id"
                                        placeholder="Select Role"
                                        errors={errors.role_id}
                                        aData={aRoles}
                                        handleChange={handleChange}
                                        selectValue={values.role_id}
                                    />
                                </div>
                            </div>
                            {
                                aOrgs.status ?
                                    <div className="row">
                                        {
                                            aOrgs.data.map(
                                                (row, i) => (
                                                    <div className="col-md-3" key={i}>
                                                        <Checkbox
                                                            name="orgs"
                                                            placeholder={row.name}
                                                            id={'orgs'+i}
                                                            errors=""
                                                            value={row.id}
                                                            handleChange={handleChange}
                                                            values={values.orgs}
                                                        />
                                                    </div>
                                                )
                                            )
                                        }
                                    </div>
                                : ""
                            }

                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !btnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save changes
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }

                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default UserCreateModel
