import React, { useEffect,useState} from 'react';
import {  Nav, Pagination } from "react-bootstrap";
import {Link} from 'react-router-dom';


const PagePagination = ({dataCount, perPage, pegiFunction}) => {

    const [activePegi, setActivePagi] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [paginateLimit, setPaginateLimit] = useState(5);
    const [startPeginate, setStartPeginate] = useState(1);
    const [items, setItems] = useState([]);
    const [hideRight, sethideRight] = useState(false);
    const [hideLeft, setHideLeft] = useState(false);

    useEffect(() => {
        setTimeout(()=>{
            let totalCount = dataCount || 0
            if(totalCount > 0){
                let pages = Math.ceil(totalCount / perPage);
                let pageLimit = (paginateLimit > pages ? pages : paginateLimit) - 1
                paginator(pageLimit,pages)
            }
        },500)
    }, [dataCount]);

    const changePaginationNext = () => {
        setStartPeginate(startPeginate+1)
        setActivePagi(activePegi+1)
        pegiFunction(activePegi+1,perPage)

    }

    const changePaginationPrevious = () => {
        setActivePagi(activePegi-1)
        setStartPeginate(startPeginate-1)
        pegiFunction(activePegi-1,perPage)
    }

    const changePagination = (page) => {
        console.log(page)
        setActivePagi(page)
        pegiFunction(page,perPage)
    }

    const paginator = (pageLimit, totalPages) => {
        setPaginateLimit(pageLimit)
        setTotalPages(totalPages)
        let pegiItems = [];
        for (let number = startPeginate; number <= (startPeginate + pageLimit); number++) {
            if(totalPages <= (startPeginate + pageLimit)){
                sethideRight(true)
            }
            if(totalPages > (startPeginate + pageLimit)){
                sethideRight(false)
            }
            if(startPeginate === 1){
                setHideLeft(true)
            }
            if(startPeginate >  1){
                setHideLeft(false)
            }
            pegiItems.push(
                <Pagination.Item key={number} active={number === activePegi} onClick={()=>changePagination(number)}>
                    {number}
                </Pagination.Item>
            );
        }
        setItems(pegiItems)
    }

    useEffect(() => {
        paginator(paginateLimit,totalPages)
    }, [activePegi]);

    const pag = (size, gutter, variant, bg, circle) => (
        <Pagination
            size={size}
            className={`  ${gutter ? "pagination-gutter" : ""} ${
            variant && `pagination-${variant}`
                } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
        >

            {
                !hideLeft ?
                    <li className="page-item page-indicator" onClick={()=>changePaginationPrevious()}>
                        <Link className="page-link" to="#">
                            &lt;&lt;
                        </Link>
                    </li>
                    :
                    <li className="page-item page-indicator">
                        <Link className="page-link" style={{backgroundColor:'#B0B0B0'}} to="#">
                            &lt;&lt;
                        </Link>
                    </li>
            }
            {items}
            {
                !hideRight ?
                    <li className="page-item page-indicator" onClick={()=>changePaginationNext()} >
                        <Link className="page-link" to="#">
                            &gt;&gt;
                        </Link>
                    </li>
                    :
                    <li className="page-item page-indicator" >
                        <Link className="page-link" style={{backgroundColor:'#B0B0B0'}} to="#">
                            &gt;&gt;
                        </Link>
                    </li>
            }

        </Pagination>
    );

    return (
        <Nav>{pag("xs", true, "warning", true, false)}</Nav>
    )

}

export default PagePagination