import React from 'react';

const InputDropdown = ({name,placeholder,id, selected_value, errors, aData, value, handleChange}) => {
    return (
        <div>
            <label className="mb-0 mt-2">{placeholder}</label>
            <div className="input-group wow fadeInUp mt-0" data-wow-delay="1.8s">
                <select defaultValue={value}
                        className="form-control"
                        style={{width:'100%'}}
                        name={name}
                        id={id}
                        onChange={handleChange}
                >

                    <option key="0" value="">{placeholder}</option>
                    {
                        aData.length > 0 && aData.map(
                            row => <option selected={row.id === value ? "selected":""} value={row.id} key={row.id}>{row.name}</option>
                        )
                    }
                </select>
                <div
                    id={`${id}-error`}
                    className="invalid-feedback animated fadeInUp p-0"
                    style={{ display: "block" }}
                >
                    {errors && errors}
                </div>
            </div>
        </div>
    )
}


export default InputDropdown