import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, NavLink } from 'react-router-dom'
import {Formik} from 'formik';
import * as Yup from "yup";
import BgImage from "../../images/bg.png";
import logo from "../../images/logo-full.png";
import logolight from "../../images/logo-full-light.png";
import pol from "../../images/pol.jpg";
import Input from "../components/Magpie/Input";
import Password from "../components/Magpie/Password";
import {loginAsync} from "../../store/actions/login.action";
import ToastAlert from "../components/Magpie/ToastAlert";
import useGetData from "../../hooks/useGetData"


function Login (props) {

    const navigate = useNavigate()
    const {loginFunc,login} = props
    const [message,setMessage] = useState("");
    const [status, setStatus] = useState(false);
    const [fired, setFired] = useState(false);
    const [btnLoad, setBtnLoad] = useState(false);
    let unauthorized =  localStorage.getItem("unauthorized");

    const HandleLogin = async (values) => {
        setFired(false)
        setBtnLoad(true)
        const respond = await useGetData('/login',values);
        setMessage(respond.message)
        setStatus(respond.status)
        setBtnLoad(false)
        if(respond.status){
            loginFunc(respond)
		}else{
            setFired(true)
		}
    }

    useEffect(() => {
        if(login.isLogin)
            navigate("/admin");
    }, [login]);

    const loginSchema = Yup.object().shape({
        email: Yup.string()
            .required("Please enter a username")
            .email('Please provide valid email'),
        user_password: Yup.string()
            .required("Please provide a password"),
    });

  	return (
		<div className="container h-100" style={{marginTop:'5%'}}>
            {
                fired ? <ToastAlert message={message} status={login.isLogin} /> : ""
            }
			<div className="row h-100 align-items-center justify-contain-center">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-body p-0">
							<div className="row m-0">
								<div className="col-xl-6 col-md-6 sign text-center sign-bg pt-10">
									<div>
										{
											<img style={{marginTop:'100px'}} src="/LIVEEZ-Final-Logo.png" className=" slideskew img-fix bitcoin-img sd-shape7" />
										}
									</div>	
								</div>
								<div className="col-xl-6 col-md-6" >
									<div className="sign-in-your px-2">
										<h4 className="fs-20 ">Sign in your account</h4>
										<span>Welcome back! Login with your email and password</span>
										<Formik
											initialValues={{
                                                email: "",
                                                user_password: ""
                                            }}
											validationSchema={loginSchema}
											onSubmit={(values, { setSubmitting }) => {
                                                setTimeout(() => {
                                                    HandleLogin(values)
                                                    setSubmitting(false);
                                                }, 400);
                                            }}

										>
                                            {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
												<form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
													<div className="row ">
														<div className="col-md-12">
															<Input
																name="email"
																id="email"
																placeholder="Email"
																errors={errors.email}
																value={values.email}
																handleChange={handleChange}
															/>
														</div>
														<div className="col-md-12">
															<Password
																name="user_password"
																id="user_password"
																placeholder="Password"
																errors={errors.user_password}
																value={values.user_password}
																handleChange={handleChange}
															/>
														</div>
														<div className="col-md-12 mt-2">
															{
																!btnLoad ?
																	<button name="submit" type="submit" value="Login" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Login</button>
																	:
																	<h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                                            }
														</div>
														<div className="col-md-12 mt-2">
															<NavLink to={"/forgot-password"} className="cursor-point">Forgot Password</NavLink>
														</div>
													</div>
												</form>
                                            )}
										</Formik>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>		
  	);
};

const initMapDispatchToProps = dispatch => ({
    loginFunc: (data) => dispatch(loginAsync(data))
})

const initMapStateToProps = state => ({
    login: state.auth
})

export default connect(initMapStateToProps,initMapDispatchToProps)(Login);