import React,{useState,useEffect} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import Input from "../components/Magpie/Input";
import * as Yup from "yup";
import {Formik} from 'formik';
import ToastAlert from "../components/Magpie/ToastAlert";
import useIngest from "../../hooks/useIngest"
import { connect, useDispatch } from 'react-redux';

const validateSchema = Yup.object().shape({
    email: Yup.string()
        .required("Please provide a email")
        .email('Please provide valid email'),
});

const ForgotPassword = ({login}) => {
    const navigate = useNavigate();
    const [fired, setFired] = useState(false);
    const [message, setMessage] = useState(false);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if(login.isLogin)
            navigate("/admin");
    }, [login]);

    const HandleFormSubmit = async (data) => {
        setFired(false)
        const respond = await useIngest('/recover-password',data);
        setFired(true)
        setMessage(respond.message)
        setStatus(respond.status)
        if(respond.status){
            setTimeout(()=>{
                navigate("/confirm-password/"+respond.data);
            },3000)
        }
    }

    return(
        <div className="vh-100">
          <div className="container h-100">
              {
                  fired ? <ToastAlert message={message} status={status} /> : ""
              }
            <div className="row h-100 align-items-center justify-contain-center">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="row m-0">
                      <div className="col-xl-6 col-md-6 sign text-center">
                          <div>
                              {
                                  <img style={{marginTop:'60px'}} src="/LIVEEZ-Final-Logo.png" className=" slideskew img-fix bitcoin-img sd-shape7" />
                              }
                          </div>
                      </div>
                      <div className="col-xl-6 col-md-6" >
                        <div className="sign-in-your px-2">
                          <h4 className="fs-20 ">Forgot Password</h4>
                          <Formik
                              initialValues={{
                                  email: ""
                              }}
                              validationSchema={validateSchema}
                              onSubmit={(values, { setSubmitting }) => {
                                  setTimeout(() => {
                                      HandleFormSubmit(values)
                                      setSubmitting(false);
                                  }, 400);
                              }}

                          >
                              {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                                  <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                                    <div className="row ">
                                      <div className="col-md-12 mb-2">
                                        <Input
                                            name="email"
                                            id="email"
                                            placeholder="Enter Email"
                                            errors={errors.email}
                                            value={values.email}
                                            handleChange={handleChange}
                                        />
                                      </div>
                                      <div className="col-md-12 p-t20">
                                        <button name="submit" type="submit" value="submit" className="btn btn-primary wow fadeInUp" data-wow-delay="2.3s">Recover Password</button> &nbsp;&nbsp;
                                      </div>
                                    </div>
                                  </form>
                              )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );

}

const initMapStateToProps = state => ({
    login: state.auth
})

export default connect(initMapStateToProps)(ForgotPassword);