import axios from 'axios';
const csrf =  document.querySelector('meta[name="csrf-token"]').content
const Api = axios.create({
    headers: {
        Accept : `application/json`,
        post: {        // can be common or any other method
            header: csrf,
        }

    },
    //baseURL: `http://localhost:8000/api/`
    baseURL: `/api/`
});

export default Api

