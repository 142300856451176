import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import Input2 from "../../Magpie/Input2";

const userSchema = Yup.object().shape({
    display_name: Yup.string()
        .required("Please provide a name"),
    email: Yup.string()
        .required("Please enter a username")
        .email('Please provide valid email'),
    mobile_number: Yup.string()
        .required("Please provide mobile number")
});

const ContactModel = ({isShow,hideFunc,title,submitFunction, emailBtnLoad, name}) => {

    return (
        <Modal className="fade"  backdrop="static"  show={isShow} onHide={hideFunc} centered>
            <Modal.Header style={{borderBottom:'none', paddingBottom:'5px'}}>
                <Modal.Title><i className="fa fa-envelope me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body style={{paddingTop:'0px'}}>
                <p style={{marginBottom:'5px'}}>Share your contact details with {name}</p>
                <div style={{borderBottom:'1px solid #EEEEEE'}}></div>
                <div style={{paddingBottom:'10px'}}></div>
                <Formik
                    initialValues={{
                        display_name: '',
                        email: '',
                        mobile_number: ''
                    }}
                    validationSchema={userSchema}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            <div className="row ">
                                <div className="col-md-12">
                                    <Input2
                                        name="display_name"
                                        id="display_name"
                                        placeholder="Hi I`m"
                                        errors={errors.display_name}
                                        value={values.display_name}
                                        handleChange={handleChange}
                                        label="Name"
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <Input2
                                        name="email"
                                        id="email"
                                        placeholder="My Email"
                                        errors={errors.email}
                                        value={values.email}
                                        handleChange={handleChange}
                                        label="Email"
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-12">
                                    <Input2
                                        name="mobile_number"
                                        id="mobile_number"
                                        placeholder="My Contact Number"
                                        errors={errors.mobile_number}
                                        value={values.mobile_number}
                                        handleChange={handleChange}
                                        label="Contact Number"
                                    />
                                </div>
                            </div>
                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !emailBtnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Send
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }


                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default ContactModel
