import React, { useState,useContext } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import "react-color-palette/css";

const OrgThemeManageModel = ({isShow,hideFunc,title,submitFunction,themeFunction,thm, btnLoad}) => {

    const [theme, setTheme] = useState(thm);

    return (
        <Modal className="fade bd-example-modal-lg"  backdrop="static"  show={isShow} size="lg" onHide={hideFunc}>
            <Modal.Header>
                <Modal.Title><i className="fa fa-user me-2" /> {title}</Modal.Title>
                <Button variant="" className="btn-close" onClick={hideFunc}></Button>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        profile_image:''
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            submitFunction(values)
                            setSubmitting(false);
                        }, 400);
                    }}

                >
                    {({ values,errors,handleChange,handleBlur,handleSubmit,isSubmitting }) => (
                        <form className="dz-form dzForm style-1 contact-bx" noValidate onSubmit={handleSubmit}>
                            {/*<div className="row ">*/}
                                {/*<div className="col-xl-12">*/}
                                    {/*<FileUpload*/}
                                        {/*name="bg_image"*/}
                                        {/*id="bg_image"*/}
                                        {/*errors={''}*/}
                                        {/*placeholder="Background Image"*/}
                                        {/*handleChange={handleUpload}*/}
                                    {/*/>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row">
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_1');setTheme('Theme_1')}}  className="theme_color_1 m-1 cursor-pointer" bg="badge-xl">Theme_1</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_2');setTheme('Theme_2')}}   className="theme_color_2 m-1 cursor-pointer" bg="badge-xl">Theme_2</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_3');setTheme('Theme_3')}}   className="theme_color_3 m-1 cursor-pointer" bg="badge-xl">Theme_3</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_4');setTheme('Theme_4')}}   className="theme_color_4 m-1 cursor-pointer" bg="badge-xl">Theme_4</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_5');setTheme('Theme_5')}}  className="theme_color_5 m-1 cursor-pointer" bg="badge-xl">Theme_5</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_6');setTheme('Theme_6')}}   className="theme_color_6 m-1 cursor-pointer" bg="badge-xl">Theme_6</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_7');setTheme('Theme_7')}}   className="theme_color_7 m-1 cursor-pointer" bg="badge-xl">Theme_7</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_8');setTheme('Theme_8')}}   className="theme_color_8 m-1 cursor-pointer" bg="badge-xl">Theme_8</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_9');setTheme('Theme_9')}}  className="theme_color_9 m-1 cursor-pointer" bg="badge-xl">Theme_9</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_10');setTheme('Theme_10')}}   className="theme_color_10 m-1 cursor-pointer" bg="badge-xl">Theme_10</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_11');setTheme('Theme_11')}}   className="theme_color_11 m-1 cursor-pointer" bg="badge-xl">Theme_11</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_12');setTheme('Theme_12')}}   className="theme_color_12 m-1 cursor-pointer" bg="badge-xl">Theme_12</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_13');setTheme('Theme_13')}}   className="theme_color_13 m-1 cursor-pointer" bg="badge-xl">Theme_13</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_14');setTheme('Theme_14')}}   className="theme_color_14 m-1 cursor-pointer" bg="badge-xl">Theme_14</Badge>
                                </div>
                                <div className="col-md-3">
                                    <Badge as="a" onClick={()=>{themeFunction('Theme_15');setTheme('Theme_15')}}   className="theme_color_15 m-1 cursor-pointer" bg="badge-xl">Theme_15</Badge>
                                </div>
                            </div>
                            <h5 className="mt-3">{theme !== '' ? theme + ' Selected' : ''} </h5>
                            <div className="row ">
                                <div className="mb-3"></div>
                                <Modal.Footer>
                                    <Button
                                        variant="danger light"
                                        onClick={hideFunc}
                                    >
                                        Close
                                    </Button>
                                    {
                                        !btnLoad ?
                                            <Button
                                                variant=""
                                                type="submit"
                                                className="btn btn-primary"
                                            >
                                                Save changes
                                            </Button>
                                            :
                                            <h1 className="la la-circle-o-notch la-spin me-2 text-primary " style={{fontSize:'40px'}} ></h1>
                                    }

                                </Modal.Footer>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal.Body>

        </Modal>
    )

}

export default OrgThemeManageModel
