import Api from '../../Api';

export const roleStart = () => ({
    type:"GET_ROLE_START"
})

export const roleSuccess = payload => ({
    type:"GET_ROLE_SUCCESS",
    payload
})

export const roleFail = payload => ({
    type:"GET_ROLE_FAIL",
    payload
})

export const  getRoleAsync = () => async dispatch => {
    dispatch(roleStart())
    try {
        let aData = await getRoles()
        if(aData.status){
            dispatch(roleSuccess(aData))
        }
        else
            dispatch(roleFail(aData.message))

    }catch (e){
        dispatch(roleFail(e.message))
    }
};

async function getRoles() {
    //Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.get('role-manage/role-data');
    return resp.data
}