import Api from '../../Api';

export const getCardStart = () => ({
    type:"GET_CARD_START"
})

export const getCardSuccess = payload => ({
    type:"GET_CARD_SUCCESS",
    payload
})

export const getCardFail = payload => ({
    type:"GET_CARD_FAIL",
    payload
})

export const getCardException = payload => ({
    type:"GET_CARD_EXCEPTION",
    payload
})


export const  getCardsAsync = () => async dispatch => {
    dispatch(getCardStart())
    try {
        const aData = await getCards()
        if(aData.status){
            dispatch(getCardSuccess(aData))
        }
        else
            dispatch(getCardFail(aData))

    }catch (e){
        dispatch(getCardException(e.message))
    }
};

async function getCards() {
    Api.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token")}`;
    const resp = await Api.get('cards',);
    return resp.data
}