/// Menu
// import Metismenu from "metismenujs";
import React, { useReducer, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import {Collapse} from 'react-bootstrap';
import { Link } from "react-router-dom";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import {connect} from "react-redux";
import {getPermissionAsync} from "../../../store/actions/permission.action";

const reducer = (previousState, updatedState, login) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}

const SideBar = ({getPermissionFunc,mount,permission,login}) => {

 let d = new Date();
 const {
   iconHover,
   sidebarposition,
   headerposition,
   sidebarLayout,
   ChangeIconSidebar,
 } = useContext(ThemeContext);

 const [state, setState] = useReducer(reducer, initialState);
    const [privileges,setPrivilege] = useState([]);

    useEffect(() => {
        if(mount.status){
            if(login.isLogin){
                getPermissionFunc()
            }
        }
    }, [login,mount]);

    useEffect(() => {
        if(mount.status){
            if(permission.status){
                let aPermissions = [];
                permission.data.map(
                    row => {
                        aPermissions.push({
                            title:row.privilege_name,
                            iconStyle: <i className="material-icons-outlined">{row.icon}</i>,
                            to: row.url,
                        })
                    }
                )
                setPrivilege(aPermissions)
            }
        }
    }, [permission,mount]);

 useEffect(() => {
   var btn = document.querySelector(".nav-control");
   var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
   }
    btn.addEventListener("click", toggleFunc); 
 }, []);

   let handleheartBlast = document.querySelector('.heart');
   function heartBlast(){
     return handleheartBlast.classList.toggle("heart-blast");
   }

   const [hideOnScroll, setHideOnScroll] = useState(true)
   useScrollPosition(
     ({ prevPos, currPos }) => {
       const isShow = currPos.y > prevPos.y
       if (isShow !== hideOnScroll) setHideOnScroll(isShow)
     },
     [hideOnScroll]
   )
 
  
    const handleMenuActive = status => {		
      setState({active : status});			
      if(state.active === status){				
        setState({active : ""});
      }   
   }
   const handleSubmenuActive = (status) => {		
     setState({activeSubmenu : status})
     if(state.activeSubmenu === status){
       setState({activeSubmenu : ""})			
     }    
   }
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
 
  return (
      <div
        onMouseEnter={()=>ChangeIconSidebar(true)}
        onMouseLeave={()=>ChangeIconSidebar(false)}
        className={`dlabnav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
         <PerfectScrollbar className="dlabnav-scroll">           
            <ul className="metismenu" id="menu">
                {
                    login.isLogin && privileges.length > 0 ?
                        privileges.map((data, index)=>{
                            let menuClass = data.classsChange;
                            if(menuClass === "menu-title"){
                                return(
                                    <li className={menuClass}  key={index} >{data.title}</li>
                                )
                            }else{
                                return(
                                    <li className={` ${ state.active === data.title ? 'mm-active' : ''}`} key={index} >

                                        {data.content && data.content.length > 0 ?
                                            <>
                                            <Link to={"#"}
                                                  className="has-arrow"
                                                  onClick={() => {handleMenuActive(data.title)}}
                                            >
                                                {data.iconStyle}
                                                <span className="nav-text">{data.title}</span>
                                                <span className="ms-1 badge badge-xs style-1 badge-danger">{data.update}</span>
                                            </Link>
                                            <Collapse in={state.active === data.title ? true :false}>
                                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                    {data.content && data.content.map((data,index) => {
                                                        return(
                                                            <li key={index}
                                                                className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}`}
                                                            >
                                                                {data.content && data.content.length > 0 ?
                                                                    <>
                                                                    <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
                                                                          onClick={() => { handleSubmenuActive(data.title)}}
                                                                    >
                                                                        {data.title}
                                                                    </Link>
                                                                    <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                                        <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                                            {data.content && data.content.map((data,index) => {
                                                                                return(

                                                                                    <li key={index}>
                                                                                        <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                                                                    </li>

                                                                                )
                                                                            })}
                                                                        </ul>
                                                                    </Collapse>
                                                                    </>
                                                                    :
                                                                    <Link to={data.to}>
                                                                        {data.title}
                                                                    </Link>
                                                                }

                                                            </li>

                                                        )
                                                    })}
                                                </ul>
                                            </Collapse>
                                            </>
                                            :
                                            <Link  to={data.to} >
                                                {data.iconStyle}
                                                <span className="nav-text">{data.title}</span>
                                            </Link>
                                        }

                                    </li>
                                )
                            }
                        })
                        : ""

                }

          </ul>	
          </PerfectScrollbar>
      </div>
    );
};

const initMapDispatchToProps = dispatch => ({
    getPermissionFunc: () => dispatch(getPermissionAsync())
})

const initMapStateToProps = state => ({
    login: state.auth,
    permission: state.permission,
    mount: state.mount,
})

export default connect(initMapStateToProps,initMapDispatchToProps)(SideBar);