import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";



import { Autoplay } from "swiper";

const HomeSlider = ({kpiData}) => {
	return (
		<>
			<Swiper className="mySwiper-counter position-relative overflow-hidden"						
				speed= {1500}
				parallax= {true}
				slidesPerView= {4}
				spaceBetween= {30}
				loop={false}
				modules={[ Autoplay ]}
				breakpoints = {{
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                      },	
                      576: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      991: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                      },
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
				}}
			>							
                <SwiperSlide>	
                    <div className="card counter">
                        <div className="card-body d-flex align-items-center">
                            <div className="card-box-icon">
                                <svg fill="#071ba2" viewBox="-7.36 -7.36 46.72 46.72" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>view</title> <path d="M12.406 13.844c1.188 0 2.156 0.969 2.156 2.156s-0.969 2.125-2.156 2.125-2.125-0.938-2.125-2.125 0.938-2.156 2.125-2.156zM12.406 8.531c7.063 0 12.156 6.625 12.156 6.625 0.344 0.438 0.344 1.219 0 1.656 0 0-5.094 6.625-12.156 6.625s-12.156-6.625-12.156-6.625c-0.344-0.438-0.344-1.219 0-1.656 0 0 5.094-6.625 12.156-6.625zM12.406 21.344c2.938 0 5.344-2.406 5.344-5.344s-2.406-5.344-5.344-5.344-5.344 2.406-5.344 5.344 2.406 5.344 5.344 5.344z"></path> </g></svg>
                            </div>

                            <div  className="chart-num">
                                <h2 className="font-w600 mb-0">{kpiData[0]}</h2>
                                <p>
                                    View Profile
                                </p>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>	
                <SwiperSlide>
                    <div className="card counter">
                        <div className="card-body d-flex align-items-center">
                            <div className="card-box-icon">
                                <svg viewBox="-4.8 -4.8 33.60 33.60" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#cd1818" strokeWidth="1.5" strokeLinecap="round"></path> <path d="M12 2L12 15M12 15L9 11.5M12 15L15 11.5" stroke="#cd1818" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                            </div>
                            <div className="chart-num">
                                <h2 className="font-w600 mb-0">{kpiData[1]}</h2>
                                <p>
                                    Downloads
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card counter">
                        <div className="card-body d-flex align-items-center">
                            <div className="card-box-icon">
                                <svg viewBox="-4.08 -4.08 32.16 32.16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 7.00005L10.2 11.65C11.2667 12.45 12.7333 12.45 13.8 11.65L20 7" stroke="#613583" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <rect x="3" y="5" width="18" height="14" rx="2" stroke="#613583" strokeWidth="2" strokeLinecap="round"></rect> </g></svg>
                            </div>
                            <div className="chart-num">
                                <h2 className="font-w600 mb-0">{kpiData[2]}</h2>
                                <p>
                                    Mail
                                </p>
                            </div>
                        </div>                    
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="card counter">
                        <div className="card-body d-flex align-items-center">
                            <div className="card-box-icon">
                                <svg fill="#e66100" viewBox="-11.2 -11.2 54.40 54.40" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>share</title> <path d="M0 25.472q0 2.368 1.664 4.032t4.032 1.664h18.944q2.336 0 4-1.664t1.664-4.032v-8.192l-3.776 3.168v5.024q0 0.8-0.544 1.344t-1.344 0.576h-18.944q-0.8 0-1.344-0.576t-0.544-1.344v-18.944q0-0.768 0.544-1.344t1.344-0.544h9.472v-3.776h-9.472q-2.368 0-4.032 1.664t-1.664 4v18.944zM5.696 19.808q0 2.752 1.088 5.28 0.512-2.944 2.24-5.344t4.288-3.872 5.632-1.664v5.6l11.36-9.472-11.36-9.472v5.664q-2.688 0-5.152 1.056t-4.224 2.848-2.848 4.224-1.024 5.152zM32 22.080v0 0 0z"></path> </g></svg>
                            </div>
                            <div className="chart-num">
                                <h2 className="font-w600 mb-0">{kpiData[3]}</h2>
                                <p>
                                    Share
                                </p>
                            </div>
                        </div>
                        
                    </div>                
                </SwiperSlide>
							
			</Swiper>
		</>
	)
}
export default  HomeSlider;
